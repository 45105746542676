import Conflictos from './conflictos.vue';
import ConflictoForm from './conflicto-form.vue';
import RoutesUtils from './../../js/RoutesUtils.js';

var routes = [{
        path: '/conflictos/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('conflictos', ['ADMINISTRATIVO', 'CLIENTE', 'INSPECTOR'], Conflictos),
        //keepAlive: true
    },
    // {
    //     path: '/conflictos/new/',
    //     beforeEnter: RoutesUtils.checkAuthPage,
    //     async: RoutesUtils.checkPermission('conflictos/new', ConflictoForm)
    // },
    // {
    //     path: '/conflictos/edit/:id/',
    //     beforeEnter: RoutesUtils.checkAuthPage,
    //     async: RoutesUtils.checkPermission('conflictos/edit', ConflictoForm)
    // },
    {
        path: '/conflictos/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('conflictos/view', ['ADMINISTRATIVO', 'CLIENTE', 'INSPECTOR'], ConflictoForm, { editable: false })
    },
];
export default routes;