<template>
  <f7-page :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar :title="$t('usuarios.usuarios.usuarios')" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-dynamic-list
        ref="rlist"
        :dbAdapter="Usuario"
        tableCode="USUARIOS"
        filterPosition="PANEL"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        :shownFields="['id','username','activo']"
        :massiveActions="true"
        :controlsWidth="110"
        :searchOnInit="true"
        @updatemany="onUpdateMany"
        :massiveActionsSelectAll="true"
        :mostrarBadgeFilters="false"
      >
        <template v-slot:tableLinks>
          <a class="link" href="/usuarios/new/">{{$t('usuarios.nuevo')}}</a>
        </template>
        <template v-slot:optionsColumn="{item,rlist}" style="width: 100px;">
          <f7-link icon-f7="eye" color="green" :href="`/usuarios/view/${item.id}/`" v-tooltip="$t('common.ver')"></f7-link>
          <f7-link 
            icon-f7="square_pencil" 
            color="blue" 
            :href="`/usuarios/edit/${item.id}/`"
            v-tooltip="'Editar usuario'"
          ></f7-link>
          <f7-link v-if="item.id" v-roles="{route: $f7route.url, roles: ['ADMINISTRATIVO']}" icon-f7="lock" color="orange" @click="resetPass(item.id)" v-tooltip="'Resetear contraseña'"></f7-link>
          <f7-link v-tooltip="$t('common.eliminar')" icon-f7="trash" color="red" @click="rlist.deleteItem(item.username);"></f7-link>
        </template>
      </r-dynamic-list>
      <!-- rList Table CARD-->
    </f7-page-content>
  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 20px;
    margin-left: 20px;
    font-weight: bold;
  }
</style>
<script>
import rDynamicList from "../../components/rDynamicList.vue";
import Application from "./../../js/db/Application.js";
import Usuario from "./../../js/db/Usuario.js";
import Inspector from "./../../js/db/Inspector.js";
import Cliente from "./../../js/db/Cliente.js";
import UsuarioCliente from "./../../js/db/UsuarioCliente.js";
export default {
  components: {
    rDynamicList
  },
  data() {
    return {
      Application,
      Usuario,
      Inspector,
      Cliente,
      UsuarioCliente,
      rPanelOpenedByBreakpoint: false,
      inspeccion: {}
    };
  },
  props: {
    applicationId: {
      type: String
    }
  },
  computed: {},
  methods: {
    showCheckInList: function(item) {
      return false; 
      // return item.estado && item.estado.estado && item.estado.estado == "ABIERTA"; 
    },
    resetPass: function(itemId) {
      var self = this;
      var app = self.$f7;
      app.dialog.confirm(
        "¿Seguro que desea resetear la contraseña del usuario seleccionado?",
        "Resetear Contraseña",
        function() {
          app.preloader.show();
          Usuario.resetPass({itemId: itemId})
            .then(function(res) {
              app.preloader.hide();
              app.toast
                .create({
                  text: 'Contraseña Reseteada',
                  position: "center",
                  closeTimeout: 2000
                })
                .open();
            })
            .catch(function(error) {
              app.preloader.hide();
              app.dialog.alert(error.error.message);
            });
        }
      );
    },
    onUpdateMany: function(response) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      if(response && response.list) {
        response.list.forEach( res => {
          let approles = res.applications.find(a => {return a.applicationId == self.applicationId;});
          res.rol = approles.roles[0];
          if (res.rol == 'INSPECTOR') {
            Inspector.get({itemId: res.username}).then(function(inspector) {
              inspector.activo = res.activo;
              Inspector.save({itemId: inspector.id, data: inspector});
            });
          } else {
            UsuarioCliente.get({itemId: res.username}).then(function(usuarioCliente){
              usuarioCliente.activo = res.activo;
              UsuarioCliente.save({itemId: usuarioCliente.id, data: usuarioCliente});
            });
          }
        });
      }
    }
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;

    // busco el id de la aplicación
    Application.getByAppcode("SIMECAR_INSPECCIONES")
      .then(function(res) {
        self.applicationId = res?res.id:"";
      })
      .catch(function(error) {
        app.dialog.alert(error.error.message);
      });
    /*app.dataTable.create({
      el: self.$refs.datatable
    });*/
  }
};
</script>