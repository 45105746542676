<template>
  <div>
    <r-badge class="badge-filtros" v-if="mostrarBadgeFiltros()" color="red" :text="getTextFiltrosActivos()"></r-badge>
    <!-- <slot name="before" :rlist="self"></slot> -->
    <f7-card-header>
      <div class="data-table-header" v-if="Object.keys(selected).length==0">
        <div class="data-table-links width-100">
          <slot name="tableLinks" :rlist="self"></slot>
          <r-form class="margin-left" v-if="tableUse" ref="rformConsultas"
            :defaultData="{consulta: getConsultaDefecto()}"
          >
            <template v-slot="{rform}">
              <div class="data-table-actions">
                <f7-list no-hairlines-md>
                  <r-autocomplete
                    ref="consultasCombo"
                    floating-label
                    outline
                    :label="$t('common.consultasFavoritas')"
                    :dbItems="tableUse.consultasFavoritas"
                    :form="rform"
                    name="consulta"
                    :idProp="null"
                    labelProp="nombre"
                    :clearButton="true"
                    @change="changeConsulta(rform.formData.consulta)"
                    style="min-width: 300px;"
                  ></r-autocomplete>
                </f7-list>
                <f7-link small popup-open=".popup-guardar-consulta" icon-f7="floppy_disk" v-tooltip="$t('common.guardar')"></f7-link>
                <template v-if="rform.formData.consulta && rform.formData.consulta.nombre && rform.formData.consulta.nombre != 'Defecto'">
                  <f7-link small @click="deleteConsulta(rform.formData.consulta)" icon-f7="trash" v-tooltip="$t('common.eliminar')"></f7-link>
                  <f7-link small popup-open=".popup-shared-consulta" icon-f7="person_3_fill" v-tooltip="$t('common.compartir')"></f7-link>
                </template>
              </div>
            </template>
          </r-form>
          <f7-list no-hairlines-md class="margin-left">
            <f7-list-input
              @change="searchColumns=$event.target.value"
              autocomplete="no"
              label="Filtrar Columnas"
              floating-label
              outline
              :clearButton="true"
            >
            </f7-list-input>
          </f7-list>
        </div>
        <div class="data-table-actions">
          <f7-link
            small
            color="green"
            @click="sendToSAP=true; submitFilterForm()"
            icon-f7="dog"
            v-tooltip="'Guau Guau'"
            v-if="Utils.getUser() && Utils.getUser().username == 'SIMECAL'"
          ></f7-link>
          <f7-link
            ref="btnSearchFilterForm"
            small
            @click="submitFilterForm"
            icon-f7="search"
            v-tooltip="$t('common.buscar')"
          ></f7-link>
          <f7-link
            small
            @click="clearFilter"
            icon-f7="bin_xmark"
            v-tooltip="$t('common.cleanFilter')"
          ></f7-link>
          <f7-link small @click="reload()" icon-f7="arrow_counterclockwise" v-tooltip="$t('common.actualizarTabla')"></f7-link>
          <f7-link v-if="self.tableUse && self.tableUse.opciones && self.tableUse.opciones.exportarExcel" small @click="exportExcel(tableUse.nombre + '.xlsx')" icon-f7="doc_text_fill" v-tooltip="$t('common.exportarExcel')"></f7-link>
          <f7-link
            v-if="filterPosition == 'PANEL'"
            small
            @click="$refs.rpanel.toggle()"
            icon-f7="line_horizontal_3_decrease"
            v-show="!rPanelOpenedByBreakpoint"
          ></f7-link>
        </div>
      </div>
      <div class="data-table-header-selected display-flex" v-else>
        <!-- Selected table title -->
        <div class="data-table-title-selected">
          <span
            class="data-table-selected-count"
          >{{$tc('rlist.n_registros_seleccionados', Object.keys(selected).length, {count:Object.keys(selected).length}) }}</span>
        </div>
        <!-- Selected table actions -->
        <div class="data-table-actions">
          <f7-button v-if="massiveActionsData && getActiveColumnsMasivo().length" class="display-flex" @click="popupCambioMasivoOpened = true" fill icon-f7="pencil_ellipsis_rectangle">{{$t('common.cambiosmasivos')}}</f7-button>
          <slot name="massiveActions" :selecteds="selected"></slot>
        </div>
      </div>
    </f7-card-header>
    
    <div :class="classContent">
      <slot name="beforeInner" :rlist="self"></slot>
      <r-form 
        ref="rformfilterHeader"
        @submit="setFilter($event);"
        :filtersName="filtersName"
        class="full-height table-content"
        :defaultData="mandatoryFilter"
        classContent="full-height"
        @scroll="listScroll($event)"
      >
        <template v-slot="{rform}">
          <div v-if="tableMode" class="dynamic-list width-100" ref="dl">
            <div class="dynamic-list-content" :style="styleWidthLinea()">
              <div class="thead-sticky">
                <div class="line" :style="styleWidthLinea()">
                  <div class="cell checkbox-cell left-column-sticky sticky" :style="`width: ${widthCheckColumn}px;`">
                    <label class="checkbox" v-if="massiveActionsSelectAll">
                      <input type="checkbox" @change="toggleSelectAll($event.target.checked)" />
                      <i class="icon-checkbox"></i>
                    </label>
                    <label v-else-if="massiveActionsData">
                      <div @click="toggleSelectAll()">
                        <f7-icon 
                          class="pointer"
                          v-if="Object.keys(selected).length" 
                          f7="clear_fill" 
                          :title="$t('common.desmarcarSeleccionados')"
                        ></f7-icon>
                      </div>
                    </label>
                  </div>
                  <div
                    v-for="(header, idx) in getColumnsFiltered()" :key="'header-' + header.pathName + '-' + idx"
                    :style="'min-width:1em; background-color: ' + header.bgColor + ';' + styleWidthCelda(header)"
                    @click="!header.disableOrder ? setOrder(header.pathName) : 'nada'"
                    :class="customHeaderClasses(header)"
                    class="cell"
                  >
                  <f7-link 
                    v-if="filterPosition == 'HEADER' && header.filtro && header.filtro.activo" 
                    small icon-f7="multiply_circle_fill" class="pointer filter-icon-delete" 
                    v-tooltip="$t('common.borrarFiltro')" 
                    @click.stop="removeFilter(header)"
                  >
                  </f7-link>
                  <f7-link 
                    v-if="filterPosition == 'HEADER' && header.filtro && header.filtro.activo" 
                    small icon-material="filter_list" class="pointer filter-icon" 
                    v-tooltip="$t('common.filtrar')" 
                    @click.stop="$f7.popover.get('[pathname=\'' + header.pathName + '\']').open($event.target)"
                  >
                    <f7-popover 
                      :closeByBackdropClick="false" 
                      :backdrop="false" 
                      closeOnEscape
                      :pathname="header.pathName"
                    >
                      <f7-navbar :title="$t('common.filtro')">
                        <f7-nav-right>
                          <f7-link popover-close icon-f7="multiply"></f7-link>
                        </f7-nav-right>
                      </f7-navbar>
                      <f7-list no-hairlines-md>
                        <r-list-filter-item
                          :column="header"
                          :form="rform"
                          :viewTooltip="false"
                          :ref="'header-item-' + header.pathName"
                          @keyup.enter.native="onPressEnter"
                        >
                        </r-list-filter-item>
                      </f7-list>
                    </f7-popover>
                  </f7-link>
                  {{$t(header.nombre)}}</div>
                  <div :style="'width: '+controlsWidth+'px'" class="cell label-cell text-align-center right-column-sticky" @click="$refs.popoverColumnas.open($event.target)">
                    <f7-link icon-f7="gear_alt_fill" class="pointer" v-tooltip="$t('common.configurarColumnas')" ></f7-link>
                  </div>
                </div>
              </div>
              <!-- <slot name="header" :rlist="self"></slot> -->
              <div class="ptr-content" :style="styleWidthLinea()">
                <div class="ptr-preloader" style="position:absolute;">
                  <div class="preloader"></div>
                  <div class="ptr-arrow"></div>
                </div>
              </div>
              <div class="contenido-lista">
                <template v-if="!searched && !loadingInfinite">
                  <div colspan="100%" :style="`height:${vlData.topPosition}px`"></div>
                  <div colspan="100%">
                    <div colspan="100%" class="cell text-align-center">{{$t('common.firstSearch')}}</div>
                  </div>
                </template>
                <template v-else-if="!count && !loadingInfinite && !loadingAggregations">
                  <div colspan="100%" :style="`height:${vlData.topPosition}px`"></div>
                  <div colspan="100%">
                    <div colspan="100%" class="cell text-align-center">{{$t('common.noResults')}}</div>
                  </div>
                </template>
                <template v-else>
                  <div colspan="100%" :style="`height:${vlData.topPosition}px`"></div>
                  <!-- Items -->
                    <div v-for="(item) in vlData.items" class="line" :key="tableUse.codigo + getPrimary(item)" @click="itemClicked = item" :class="{trclicked : itemClicked == item}"  :style="styleWidthLinea()">
                      <div class="cell checkbox-cell left-column-sticky sticky" :style="`width: ${widthCheckColumn}px;`">
                        <label class="checkbox" v-if="funcShowCheck(item)">
                          <input
                            type="checkbox"
                            :checked="getPrimary(item) in selected"
                            @change="toggleSelect(item, $event.target.checked)"
                          />
                          <i class="icon-checkbox"></i>
                        </label>
                      </div>
                      <div
                        v-for="(column) in getColumnsFiltered()" :key="'column-' + column.pathName"
                        :style="'background-color: ' + column.bgColor + ';' + styleWidthCelda(column)"
                        :class="customColumnClasses(column)"
                        class="cell"
                      >
                        <template v-if="column.tipo == 'FECHA'">
                          <div :title="cellText(item, column)">
                            {{cellText(item, column)}}
                          </div>
                        </template>
                        <template v-else-if="column.tipo == 'BADGE'">
                          <r-badge v-if="getValorItem(item, column)" :color="getColorItem(item, column)" :text="(getValorItem(item, column) || column.valorVacio) + (column.sufijo || '')"></r-badge>
                          <template v-else>{{column.valorVacio}}</template>
                        </template>
                        <template v-else-if="column.tipo == 'BOOLEAN'">
                          <r-badge v-if="cellText(item, column) != ''" :color="getValorItem(item, column) ? 'green' : 'red'" :text="cellText(item, column)"></r-badge>
                          <template v-else>{{column.valorVacio}}</template>
                        </template>
                        <template v-else-if="column.tipo == 'NUMERO'">
                          <div :title="cellText(item, column)">
                            {{cellText(item, column)}}
                          </div>
                        </template>
                        <template v-else-if="column.tipo == 'LISTA'">
                          <f7-link v-if="!getValorItem(item, column)" class="badge color-red"> 
                            {{$t('common.sin')}} {{$t(column.nombre)}}
                          </f7-link>
                          <f7-link v-else class="badge color-green pointer" @click="abrirPopupListado(item, column)"> 
                            {{$t('common.ver')}} {{$t(column.nombre)}} ({{getValorItem(item, column).length}})
                          </f7-link>
                        </template>
                        <template v-else-if="column.tipo == 'COMENTARIO'">
                          <div class="columnaComentario" v-tooltip="(getValorItem(item, column) || column.valorVacio) + ' ' + (column.sufijo || '') ">{{getValorItem(item, column) || column.valorVacio}} {{column.sufijo || ''}}</div>
                        </template>
                        <template v-else>
                          <div :title="(getValorItem(item, column) || column.valorVacio) + ' ' + (column.sufijo || '')">
                            {{getValorItem(item, column) || column.valorVacio}} {{column.sufijo || ''}}
                          </div>
                        </template>
                      </div>
                      <div class="cell text-align-right right-column-sticky" :style="'width: '+controlsWidth+'px'">
                        <slot name="optionsColumn" :item="item" :rlist="self"></slot>
                      </div>
                    </div>
                </template>
                <div
                  :style="`height:${vlData.listHeight-vlData.topPosition-(vlData.toIndex-vlData.fromIndex)*vlParams.height}px`"
                >
                  <div class="cell" colspan="100%" style="height:0px;padding:0!important;">
                    <div
                      v-show="loadingInfinite && !loadingPtr"
                      class="preloader infinite-scroll-preloader"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-else>
            <slot name="header" :rlist="self"></slot>
            <div class="ptr-preloader">
              <div class="preloader"></div>
              <div class="ptr-arrow"></div>
            </div>
            <ul class="ptr-content">
              <div :style="`height:${vlData.topPosition}px`"></div>
              <slot :vlData="vlData" :rlist="self"></slot>
              <div
                :style="`height:${vlData.listHeight-vlData.topPosition-(vlData.toIndex-vlData.fromIndex)*vlParams.height}px`"
              >
                <div
                  v-show="loadingInfinite && !loadingPtr"
                  class="preloader infinite-scroll-preloader"
                ></div>
              </div>
            </ul>
          </template>
          <slot v-if="!loadingPtr&&!loadingInfinite&&!vlData.items.length" name="empty"></slot>
        </template>
      </r-form>
      <!-- Filtros -->
      <rPanel
        ref="rpanel"
        side="right"
        @openedByBreakpoint="rPanelOpenedByBreakpoint=true"
        @closedByBreakpoint="rPanelOpenedByBreakpoint=false"
        v-slot="{rpanel}"
        v-if="filterPosition == 'PANEL'"
      >
        <r-form 
          ref="rformfilter"
          @submit="setFilter($event); rpanel.close();"
          :filtersName="filtersName"
          :defaultData="mandatoryFilter"
        >
          <template v-slot:before="{rform}">
            <f7-toolbar>
              <div>{{$t('common.filtro')}}</div>
              <f7-link icon-f7="bin_xmark" @click="rform.clear();" v-tooltip="$t('common.cleanFilter')"></f7-link>
            </f7-toolbar>
          </template>
          <template v-slot="{rform}">
            <f7-list no-hairlines-md>
              <r-list-filter-item
                v-for="column in getActiveColumnsFiltro()" 
                :key="'column-filtro-' + getFiltroPathName(column)"
                :column="column"
                :form="rform"
                @keyup.enter.native="onPressEnter"
              ></r-list-filter-item>
            </f7-list>
          </template>
        </r-form>
      </rPanel>
    </div>
    <f7-card-footer>
      <span v-if="!loadingAggregations">
        <b>{{$tc('rlist.n_registros', count, {count: count})}}</b>
      </span>
      <div class="aggregation-div">
        <f7-row class="row-aggregation margin-left">
          <div
            v-if="loadingAggregations"
            class="preloader infinite-scroll-preloader color-black"
          >
            <span class="preloader-inner">
              <span class="preloader-inner-circle"></span>
            </span>
          </div>
          <div
            v-else
            v-for="agregacion in getActiveAgregaciones()" 
            :key="'agregacion-' + agregacion.as"
            class="col-aggregation"
            :class="getClassesAgregaciones()"
          >
            <b>{{$t(agregacion.nombre)}} <sup class="subindice" v-if="agregacion.conCount">({{getAgregacionCount(agregacion) || 0 | numberFormat(0, ',', '.')}})</sup>: {{getAgregacionValor(agregacion) || agregacion.valorVacio | numberFormat(agregacion.formato, ',', '.')}}  {{agregacion.sufijo || ''}}</b>
          </div>
        </f7-row>
      </div>
      <span style="width:7em;" class="label-cell" @click="$refs.popoverAgregaciones.open($event.target)">
        <f7-link icon-f7="gear_alt_fill" class="pointer" v-tooltip="$t('common.configurarAgregaciones')" ></f7-link>
      </span>
    </f7-card-footer>

    <f7-popover :closeByBackdropClick="false" :backdrop="false" closeOnEscape ref="popoverColumnas" @popover:closed="forceUpdate()" style="width: 250px;"> 
      <f7-navbar :title="$t('common.configurarColumnas')">
        <f7-nav-right>
          <f7-link popover-close icon-f7="multiply"></f7-link> 
        </f7-nav-right>
      </f7-navbar>
      <f7-list class="list-columnas">
        <f7-list-item
          checkbox
          title="Todas"
          name="todas-columnas"
          :checked="getActiveColumns().length === columns.length"
          :indeterminate="getActiveColumns().length !== columns.length"
          @change="onConfigColumnsAllChange"
        >
          <ul slot="root" class="padding-left-half">
            <Container
              @drop="onDrop(columns, $event)"
              lock-axis="y"
              style="z-index: 12000;"
            >
              <Draggable
                v-for="(columna, idx) in columns"
                :key="'columna-config-' + idx"
              >
                <f7-list-item
                  checkbox
                  :value="columna.pathName"
                  name="todas-columnas"
                  :checked="columna.activaUsuario"
                  :title="$t(columna.nombre)"
                  @change="columna.activaUsuario = $event.target.checked; clearAll();"
                ></f7-list-item>
              </Draggable>
            </Container>
          </ul>
        </f7-list-item>
      </f7-list>
      <f7-button
        fill
        icon-f7="floppy_disk"
        :text="$t('common.aceptar')"
        class="display-flex margin"
        popover-close
      ></f7-button>
    </f7-popover>
    <f7-popover :closeByBackdropClick="false" :backdrop="false" closeOnEscape ref="popoverAgregaciones" @popover:closed="forceUpdate()" style="width: 250px;">
      <f7-navbar :title="$t('common.configurarAgregaciones')">
        <f7-nav-right>
          <f7-link popover-close icon-f7="multiply"></f7-link> 
        </f7-nav-right>
      </f7-navbar>
      <f7-list class="list-columnas">
        <f7-list-item
          checkbox
          title="Todas"
          name="todas-agregaciones"
          :checked="getActiveAgregaciones().length === agregaciones.length"
          :indeterminate="getActiveAgregaciones().length !== agregaciones.length"
          @change="onConfigAgregacionesAllChange"
        >
          <ul slot="root" class="padding-left-half">
            <Container
              @drop="onDrop(agregaciones, $event)"
              lock-axis="y"
              style="z-index: 12000;"
            >
              <Draggable
                v-for="(agregacion, idx) in agregaciones"
                :key="'agregacion-config-' + idx"
              >
                <f7-list-item
                  checkbox
                  :value="agregacion.as"
                  name="todas-agregaciones"
                  :checked="agregacion.activaUsuario"
                  :title="$t(agregacion.nombre)"
                  @change="agregacion.activaUsuario = $event.target.checked; clearAll();"
                ></f7-list-item>
              </Draggable>
            </Container>
          </ul>
        </f7-list-item>
      </f7-list>
      <f7-button
        fill
        icon-f7="floppy_disk"
        :text="$t('common.aceptar')"
        class="display-flex margin"
        popover-close
      ></f7-button>
    </f7-popover>

    <f7-popup ref="popupGuardarConsulta" class="popup-guardar-consulta" @popup:open="$refs.rformGuardarConsulta.loadFilters( {nombreConsulta: $refs.rformConsultas.formData.consulta.nombre || ''})">
      <f7-navbar :title="$t('common.guardarConsulta')">
        <f7-nav-right>
          <f7-link popup-close icon-f7="multiply"></f7-link>
        </f7-nav-right>
      </f7-navbar>
      <r-form ref="rformGuardarConsulta">
        <template v-slot="{rform}">
          <f7-list no-hairlines-md>
            <r-input
              floating-label
              outline
              :label="$t('common.nombre')"
              :form="rform"
              name="nombreConsulta"
              :clearButton="true"
            ></r-input>
            <f7-button
              fill
              icon-f7="floppy_disk"
              :text="$t('common.guardar')"
              class="display-flex margin"
              @click="guardarConsulta(rform.formData.nombreConsulta)"
            ></f7-button>
          </f7-list>
        </template>
      </r-form>
    </f7-popup>

    <f7-popup ref="popupCambioMasivo" v-if="popupCambioMasivoOpened" :opened="true" @popup:closed="popupCambioMasivoOpened = false">
      <f7-navbar :title="$t('common.cambiosmasivos')">
        <f7-nav-right>
          <f7-link popup-close icon-f7="multiply"></f7-link>
        </f7-nav-right>
      </f7-navbar>
      <r-form 
        ref="rformCambioMasivo"
        @submit="cambioMasivo($event);"
      >
        <template v-slot="{rform}">
          <f7-list no-hairlines-md class="popupHeight">
            <div class="margin">
              <f7-button fill type="submit" icon-f7="pencil_outline">{{$t('common.actualizarMasivamente')}}</f7-button>
            </div>
            <r-list-filter-item
              v-for="column in getActiveColumnsMasivo()" 
              :key="'column-masivo-' + getFiltroPathName(column)"
              :column="column"
              :form="rform"
              tipo="MASIVO"
            ></r-list-filter-item>
            <div class="margin">
              <f7-button fill type="submit" icon-f7="pencil_outline">{{$t('common.actualizarMasivamente')}}</f7-button>
            </div>
          </f7-list>
        </template>
      </r-form>
    </f7-popup>

    <!-- <f7-popup ref="popupSharedConsulta" class="popup-shared-consulta" @popup:closed="$refs.rformSharedConsulta.clear()">
      <f7-navbar :title="$t('common.sharedConsulta')">
        <f7-nav-right>
          <f7-link popup-close icon-f7="multiply"></f7-link>
        </f7-nav-right>
      </f7-navbar>
      <r-form ref="rformSharedConsulta">
        <template v-slot="{rform}">
          <f7-list no-hairlines-md>
            <r-autocomplete-m2m
              :dbAdapter="Empleado"
              floating-label
              outline
              :label="$t('common.empleados')"
              :form="rform"
              name="empleados"
              idProp="username"
              labelProp="nombre"
              type="textarea"
              :lineBreak="true"
            ></r-autocomplete-m2m>
            <f7-button
              fill
              icon-f7="floppy_disk"
              :text="$t('common.compartir')"
              class="display-flex margin"
              @click="compartirConsulta(rform.formData.empleados)"
            ></f7-button>
          </f7-list>
        </template>
      </r-form>
    </f7-popup> -->


  </div>
</template>
<script>

import rBadge from './rBadge';
import rPanel from "./rPanel.vue";
import rForm from "./rForm.vue";
import rInput from "./rInput.vue";
import rListFilterItem from "./rListFilterItem.vue";
import rAutocompleteM2m from "./rAutocompleteM2m.vue";
import rAutocomplete from "./rAutocomplete.vue";
import DateUtils from "../js/DateUtils";
import Utils from "../js/Utils";
import { Container, Draggable } from "vue-smooth-dnd";
import TablaUsuario from "../js/db/TablaUsuario"
import Tabla from "../js/db/Tabla"
// import Empleado from "../js/db/Empleado"

const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;
  console.log(arr, dragResult);
  //const result = [...arr];
  if (!arr) arr = [];
  var result = arr;

  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  //return result;
};

export default {
  components: {
    rBadge,
    rForm,
    rPanel,
    rInput,
    rAutocompleteM2m,
    rAutocomplete,
    Container,
    Draggable,
    rListFilterItem
  },
  data() {
    var data = this.items;
    var self = this;
    var vlParams = {
      ...{
        //el: ".virtual-list",
        items: data,
        renderExternal: this.renderExternal,
        searchAll: this.searchAll,
        setListHeight: false,
        updatableScroll: false,
        scrollableParentEl: ".virtual-list-parent",
        createUl: false,
        cache: false,
        rowsBefore: 60,
        rowsAfter: 60,
        height: this.$theme.ios ? 44 : this.$theme.md ? 48 : 38
      },
      ...this.virtualListParams
    };

    return {
      self: self,
      selected: {},
      searchColumns: null,
      count: 0,
      nomore: false,
      page: 1,
      order: this.sort,
      orderDir: this.sortDir,
      fromSort: false,
      conditions: { ...this.getFilterFromLocalStorage(), ...this.filter },
      //data: data,
      _jqxhr: null,
      loadingInfinite: false,
      loadingAggregations: false,
      loadingPtr: false,
      vlData: {
        items: []
      },
      vlParams: vlParams,
      rPanelOpenedByBreakpoint: false,
      filtersName: undefined,
      DateUtils,
      Utils,
      TablaUsuario,
      Tabla,
      // Empleado,
      columns: [],
      agregaciones: [],
      agregacionesValor: [],
      searched: false,
      tableUse: null,
      sendToSAP: false,
      popupCambioMasivoOpened: false,
      itemClicked: null,
      rowsPerPageUsed: self.rowsPerPage,
      widthCheckColumn: 40,
      massiveActionsData: self.massiveActions
    }; // FALTA: virtualListParams como prop y altura automática para parent
  },
  props: {
    classContent: {},
    classList: {},
    dbAdapter: {
      type: Object
    },
    mandatoryFilter: {
      type: Object
    },
    rowsPerPage: {
      type: Number,
      default: 100
    },
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    virtualListParams: {
      type: Object,
      default() {
        return {};
      }
    },
    primary: {
      type: Array,
      default: function() {
        return ["id"];
      }
    },
    sort: {
      type: String,
      default: "creationDate"
    },
    sortDir: {
      type: String,
      default: "DESC"
    },
    tableMode: {
      type: Boolean,
      default: false
    },
    massiveActions: {
      type: Boolean,
      default: false
    },
    massiveActionsSelectAll: {
      type: Boolean,
      default: false
    },
    infiniteScroll: {
      type: Boolean,
      default: true
    },
    shownFields: {
      type: Array,
      default: function() {
        return [];
      }
    },
    tableCode: {
      type: String,
      required: true
    },
    filtersNoCounters: {
      type: Array,
      default: function() {
        return [];
      }
    },
    mostrarBadgeFilters: {
      type: Boolean,
      default: true
    },
    searchOnInit: {
      type: Boolean,
      default: false
    },
    filterPosition: {
      //Valores HEADER o PANEL
      type: String,
      default: "HEADER"
    },
    funcShowCheck: {
      type: Function,
      default: function(item) {
        return true;
      }
    },
    beforeSearch: {
      type: Function,
      default: function(filter) {
        return filter;
      }
    },
    controlsWidth: {
      type: Number,
      default: 100
    }
  },
  mounted() {
    var self = this;
    var app = self.$f7;

    var $content = self.$$(self.$el).find(".ptr-content");
    var $infinite_content = self.$$(self.$el).find(".infinite-scroll-content");
    app.ptr.create($content[0]);
    app.infiniteScroll.create($infinite_content[0]);

    self.changeTable(self.tableCode, self.massiveActions);

    if (self.dbAdapter) {
      $content.on("ptr:refresh", function(e) {
        if (self.loadingPtr) return;
        self.initRemoteData();
      });
      if (self.infiniteScroll) {
        $infinite_content.on("infinite", function() {
          if (self.loadingInfinite || self.nomore || !self.searched) return;
          self.page = self.page + 1;
          self.getRemoteData(false);
        });
      }
    }
  },
  beforeDestroy() {
    var self = this;
    var app = self.$f7;
    self.$set(self.vlData, "items", []);
  },
  methods: {
    
    changeTable(tableCode, massiveActions = false) {
      var self = this;
      var app = self.$f7;
      app.preloader.show();
      self.Tabla.getTabla(tableCode).then(r => {
        
        self.tableUse = r;
        self.columns = self.tableUse.columnas.map(c => {
          c.activaUsuario = true;
          return c;
        });
        self.agregaciones = self.tableUse.funcionesAgregadas.map(c => {
          c.activaUsuario = true;
          return c;
        });
        self.initialColumns = JSON.parse(JSON.stringify(self.columns));
        self.massiveActionsData = massiveActions;

        self.rowsPerPageUsed = self.tableUse && self.tableUse.opciones && self.tableUse.opciones.rowsPerPage ? self.tableUse.opciones.rowsPerPage : self.rowsPerPage;

        self.changeConsulta(self.getConsultaDefecto());

        if (self.searchOnInit) {
          self.setFilter(self.getFilterData());
        }

      }).catch(error => {
        var message = typeof error == "string" ? error : error.message;
        app.dialog.alert(message);
      }).finally(_ => app.preloader.hide());

    },
    cellText(item, column){
      const self = this;
      var text;
      switch(column.tipo){
        case "FECHA":
          text = self.$options.filters.dateFormat(self.$options.filters.dateParse(self.getValorItem(item, column) || column.valorVacio), column.formato);
          break;
        case "NUMERO":
          text = self.$options.filters.numberFormat(self.getValorItem(item, column) == undefined ? column.valorVacio : self.getValorItem(item, column), column.formato, ',', '.');
          break;
        case "BOOLEAN":
          text = (self.getValorItem(item, column) != undefined && self.getValorItem(item, column) != null) ? (self.getValorItem(item, column) ? 'Si' : 'No') : "";
          return text;
      }
      if(column.sufijo){
        text = text + " " + column.sufijo;
      }
      return text;
    },
    styleWidthCelda(column){
      var self = this;
      return `width: ${self.widthCelda(column)}px;`;
    },
    styleWidthLinea(){
      var self = this;
      return `width: ${self.widthLinea()}px;`;
    },
    widthCelda(column){
      var self = this;
      return (column.opciones && column.opciones.width) ? column.opciones.width : 200;
    },
    widthLinea(){
      var self = this,
        columns = self.getColumnsFiltered();
      return Array.from(columns).reduce((acc, column) => acc + self.widthCelda(column), self.widthCheckColumn + self.controlsWidth + 8);
    },
    listScroll(eve){
      const self = this,
        scrollList = eve.target,
        scrollTop = scrollList.scrollTop,
        scrollHeight = scrollList.scrollHeight,
        offsetHeight = scrollList.offsetHeight;
      if(self.updateScroll){
        clearTimeout(self.updateScroll);
      }
      self.updateScroll = setTimeout(() => {
        if(scrollTop + offsetHeight > scrollHeight - 100 && scrollList.scrollHeight > scrollList.offsetHeight){
          self.page++;
          self.getRemoteData();
        }
      },200);
    },
    loadNextPage(){

    },
    getClassesAgregaciones() {
      const self = this;
      if (self.tableUse.opciones && self.tableUse.opciones.divAgregacionesClases) {
        return self.tableUse.opciones.divAgregacionesClases;
      } else {
        return ["col-100", "medium-50", "xlarge-33"];
      }

    },
    removeFilter(column) {
      const self = this;
      var filterItem = self.$refs['header-item-' + column.pathName];
      if (filterItem && Array.isArray(filterItem)) {
        filterItem.forEach(f => {
          f.cleanData();
        });
      } else if (filterItem) {
        filterItem.cleanData();
      }
    },
    getPathName: function(column) {
      const self = this;
      var pathName = column.pathName;
      if (self.tipo == "FILTRO") {
        pathName = column.filtro.pathName || column.pathName;
        pathName = pathName.replaceAll(".", "##");
      } else if (self.tipo == "MASIVO") {
        pathName = column.cambioMasivo.pathName || column.pathName;
      }
      return pathName;
    },
    onConfigColumnsAllChange: function ($event) {
      var self = this;
      if (!$event || !$event.target) {
        return;
      }
      self.columns.forEach(c => {
        c.activaUsuario = $event.target.checked;
      })
      self.clearAll();
    },
    onConfigAgregacionesAllChange: function ($event) {
      var self = this;
      if (!$event || !$event.target) {
        return;
      }
      self.agregaciones.forEach(c => {
        c.activaUsuario = $event.target.checked;
      })
      self.clearAll();
    },
    abrirPopupListado (item, column) {
      const self = this;
      var pathName = self.getCleanPathName(column.pathName);
      var auxFieldsItems = column.pathName.split("|")[1];
      var fieldsItem = auxFieldsItems ? auxFieldsItems.split(",") : undefined;
      var namespaces = pathName.split(".");
      var context = item;
      var iFinal = 0;
      for (var i = 0; i < namespaces.length; i++) {
        if (typeof context == "undefined") {
          let n = namespaces[i];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            context = [];
          } else {
            context = {};
          }
        }
        if (typeof context == "undefined" || context == null) context = {};
        if (Array.isArray(context)) {
          iFinal = i;
          break;
        } else {
          iFinal++;
          context = context[namespaces[i]];
        }
        
      }

      namespaces.splice(0, iFinal);

      var itemspopup = [];

      for (var i = 0; i < context.length; i++) {
        var itemList = context[i];
        var itemPopup = [];
        if (fieldsItem && fieldsItem.length) {
          for (var j = 0; j < fieldsItem.length; j++) {
            var field = fieldsItem[j];
            var namespacesItems = [...namespaces, ...field.split(".")];
            var valor = self.getValorObjectByNamespaces(itemList, namespacesItems);
            itemPopup.push(valor ? valor : "");
          }
        } else {
          itemPopup.push(itemList)
        }
        itemspopup.push(itemPopup);
      }

      var itemsStr = "<ul>";
      itemspopup.forEach(ip => {
        itemsStr = itemsStr + '<li>';
        ip.forEach((ip2, idx) => {
          if (idx > 0) {
            itemsStr = itemsStr + ' - ';
          }
          itemsStr = itemsStr + ip2;
        })
        itemsStr = itemsStr + '</li>';
      })
      itemsStr = itemsStr + '</ul>';

      if (self.popupListado) {
        self.popupListado.destroy();
      }
      // Create popup
      self.popupListado = self.$f7.popup.create({
        content: `
          <div class="popup">
            <div class="page">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner">
                  <div class="title">` + self.$t(column.nombre) + `</div>
                  <div class="right"><a href="#" class="link popup-close">x</a></div>
                </div>
              </div>
              <div class="page-content">
                ` + itemsStr + `
              </div>
            </div>
          </div>
        `.trim(),
      });
      // Open it
      self.popupListado.open();
    },
    cambioMasivo(formData) {
      var self = this;
      var app = self.$f7;

      app.preloader.show();
      if (self.dbAdapter) {
        self.dbAdapter.putAll({itemIds: Object.keys(self.selected), data: formData})
        .then(r => {
          app.dialog.alert("Cambios realizados en " + r.list.length + " objetos.");
          if (self.$refs.popupCambioMasivo) {
            self.$refs.popupCambioMasivo.close();
          }
          self.$emit("updatemany", r);
          self.reload();
        })
        .catch(error => {
          var message = typeof error == "string" ? error : error.message;
          app.dialog.alert(message);
        }).finally(_ => app.preloader.hide());
      }

    },
    compartirConsulta(empleados) {
      var self = this;
      var app = self.$f7;

      const consulta = self.$refs.rformConsultas.formData.consulta;

      if (!consulta) {
        app.dialog.alert("Elige una consulta a compartir");
        return;
      }

      if (consulta.nombre == "Defecto") {
        app.dialog.alert("La consulta por defecto no se puede compartir, elija otra.");
        return;
      }

      if (!empleados || !empleados.length) {
        app.dialog.alert("Elige empleados con quién compartir compartir");
        return;
      }

      const data = {
        codigoTabla: self.tableUse.codigo,
        usernames: empleados,
        consulta: consulta
      }
      app.preloader.show();
      TablaUsuario.sharedConsulta({data: data}).then(res => {
        app.toast
        .create({
          icon: '<i class="f7-icons">checkmark</i>',
          text: self.$t("common.consultaCompartida"),
          position: "center",
          destroyOnClose: true,
          closeTimeout: 2000
        })
        .open();
        if (self.$refs.popupSharedConsulta) {
          self.$refs.popupSharedConsulta.close();
        }
      }).catch(error => {
        var message = typeof error == "string" ? error : error.message;
        app.dialog.alert(message);
      }).finally(_ => app.preloader.hide());

    },
    deleteConsulta(consulta) {
      var self = this;
      var app = self.$f7;

      if (!consulta) {
        app.dialog.alert("Elige una consulta a borrar");
        return;
      }

      if (consulta.nombre == "Defecto") {
        app.dialog.alert("La consulta por defecto no se puede borrar, elija otra.");
        return;
      }

      const consultaDefecto = self.getConsultaDefecto();

      const data = {
        codigoTabla: self.tableUse.codigo,
        consultasFavoritas: [
          consulta
        ]
      }
      app.preloader.show();
      TablaUsuario.deleteConsulta({data: data}).then(res => {
        app.toast
        .create({
          icon: '<i class="f7-icons">checkmark</i>',
          text: self.$t("common.consultaBorrada"),
          position: "center",
          destroyOnClose: true,
          closeTimeout: 2000
        })
        .open();
        res.consultasFavoritas = [...[consultaDefecto], ...res.consultasFavoritas];
        self.tableUse.consultasFavoritas = res.consultasFavoritas;
        self.$refs.consultasCombo.reload(self.tableUse.consultasFavoritas);
        self.$set(self.$refs.rformConsultas.formData, "consulta", consultaDefecto);
        self.changeConsulta(consultaDefecto);
      }).catch(error => {
        var message = typeof error == "string" ? error : error.message;
        app.dialog.alert(message);
      }).finally(_ => app.preloader.hide());

    },
    guardarConsulta(nombre) {
      var self = this;
      var app = self.$f7;

      if (!nombre) {
        app.dialog.alert("El nombre es obligatorio");
        return;
      }
      // if (nombre == "Defecto") {
      //   app.dialog.alert("La consulta por defecto no se puede sobreescribir, elija otro nombre.");
      //   return;
      // }
      
      const consultaDefecto = self.getConsultaDefecto();

      const columns = self.getActiveColumns().map(c => c.pathName);
      const funcionesAgregadas = self.getActiveAgregaciones().map(c => c.as);
      const filtro = JSON.stringify(self.getFilterData());
      const order = self.order;
      const orderDir = self.orderDir;

      const data = {
        codigoTabla: self.tableUse.codigo,
        consultasFavoritas: [
          {
            nombre: nombre,
            pathNamesColumnas: columns,
            filtro: filtro,
            order: order,
            orderDir: orderDir,
            funcionesAgregadas: funcionesAgregadas
          }
        ]
      }
      app.preloader.show();
      TablaUsuario.createConsulta({data: data}).then(res => {
        app.toast
        .create({
          icon: '<i class="f7-icons">checkmark</i>',
          text: self.$t("common.consultaGuardada"),
          position: "center",
          destroyOnClose: true,
          closeTimeout: 2000
        })
        .open();
        if (self.$refs.popupGuardarConsulta) {
          self.$refs.popupGuardarConsulta.close();
        }

        if (!res.consultasFavoritas.filter(c => c.nombre == 'Defecto').length) {
          res.consultasFavoritas = [...[consultaDefecto], ...res.consultasFavoritas];
        }

        self.tableUse.consultasFavoritas = res.consultasFavoritas;
        var consulta = res.consultasFavoritas.filter(cf => cf.nombre == nombre)[0];
        self.$refs.consultasCombo.reload(self.tableUse.consultasFavoritas);
        self.$set(self.$refs.rformConsultas.formData, "consulta", consulta);
        self.changeConsulta(consulta, false);
      }).catch(error => {
        var message = typeof error == "string" ? error : error.message;
        app.dialog.alert(message);
      }).finally(_ => app.preloader.hide());
    },
    onDrop(arr, dropResult) {
      var self = this;
      applyDrag(arr, dropResult);
      self.clearAll();
    },
    getColumnsFiltered: function() {
      
      const self = this;
      var columns = self.getActiveColumns();
      if (self.searchColumns) {
        var searchs = self.searchColumns.split(",");
        columns = columns.filter(c => {
          var isSticky = c.opciones && c.opciones.sticky;
          var nombreTrad = self.$t(c.nombre);
          var isFiltered = searchs.some(s => s && s.trim() && nombreTrad.trim().toLowerCase().indexOf(s.trim().toLowerCase()) >= 0);
          return isSticky || isFiltered;
        });
      }
      return columns;
    },
    getActiveColumns: function() {
      const self = this;
      return self.columns.filter(c => c.activaUsuario);
    },
    getProjectionsColumns: function() {
      const self = this;
      return self.getActiveColumns().filter(c => !c.disableProjection);
    },
    getActiveAgregaciones: function() {
      const self = this;
      return self.agregaciones.filter(c => c.activaUsuario);
    },
    getAgregacionValor: function(agregacion) {
      const self = this;
      return self.agregacionesValor.filter(a => a.nombre == agregacion.as).map(a => a.valor)[0];
    },
    getAgregacionCount: function(agregacion) {
      const self = this;
      return self.agregacionesValor.filter(a => a.nombre == agregacion.as).map(a => a.count)[0];
    },
    getActiveColumnsFiltro: function() {
      const self = this;
      if (self.columns) {
        return self.columns.filter(c => c.filtro && c.filtro.activo);
      } else {
        [];
      }
    },
    getActiveColumnsMasivo: function() {
      const self = this;
      if (self.columns) {
        return self.columns.filter(c => c.activaUsuario && c.cambioMasivo && c.cambioMasivo.activo);
      } else {
        [];
      }
    },
    mostrarBadgeFiltros: function() {
      const self = this;
      return self.mostrarBadgeFilters && self.getActiveConditions().length !== 0;
    },
    getActiveConditions() {
      const self = this;
      return Object.entries(self.conditions).filter(([key, value]) => {
        const includeNoCounter = self.filtersNoCounters.includes(key);
        const includeMesOrVacia = (!key.endsWith('FechaRellena') || (key.endsWith('FechaRellena') && value)) && (!key.endsWith('FechaVacia') || (key.endsWith('FechaVacia') && value)) && !key.endsWith('MesActual');
        const includeBoolean = (!key.endsWith('BOOLEANSI') || (key.endsWith('BOOLEANSI') && value)) && (!key.endsWith('BOOLEANNO') || (key.endsWith('BOOLEANNO') && value));
        const objectNull = value != null && typeof value != 'undefined' && value != '';
        const arrayEmpty = !Array.isArray(value) || value.length;
        return !includeNoCounter && objectNull && arrayEmpty && includeMesOrVacia && includeBoolean;
      });
    },
    getConditionsWithValue() {
      const self = this;
      var conds = JSON.parse(JSON.stringify(self.conditions));
      Object.entries(conds).forEach(([key, value]) => {
        const objectNull = value != null && typeof value != 'undefined' && value != '';
        const arrayEmpty = !Array.isArray(value) || value.length;
        if (!objectNull || !arrayEmpty) {
          delete conds[key];
        }
      });
      return conds;
    },
    changeConsulta: function(consulta, clearData = true) {
      const self = this;
      if (clearData) {
        self.clearAll();
      }
      if (consulta) {
        var filtro = consulta.filtro;
        if (filtro && typeof filtro == "string") {
          try {
            filtro = JSON.parse(filtro);
          } catch (e) {
            filtro = {};
          }
        }
        self.loadFilters(filtro);

        self.order = consulta.order;
        self.orderDir = consulta.orderDir;

        if (consulta.pathNamesColumnas) {
          const noActiveColumns = self.columns.filter(c => consulta.pathNamesColumnas.indexOf(c.pathName) <= -1);

          const activeColumns = consulta.pathNamesColumnas.map(pn => {
            var column = self.columns.filter(c => c.pathName == pn)[0];
            if (column) {
              column.activaUsuario = true;
              return column;
            }
            return;
          }).filter(c => c);

          noActiveColumns.forEach(c => c.activaUsuario = false);

          self.columns = [...activeColumns, ...noActiveColumns];
        }
        if (consulta.funcionesAgregadas) {
          const noActiveAgregaciones = self.agregaciones.filter(c => consulta.funcionesAgregadas.indexOf(c.as) <= -1);

          const activeAgregaciones = consulta.funcionesAgregadas.map(pn => {
            var func = self.agregaciones.filter(c => c.as == pn)[0];
            if (func) {
              func.activaUsuario = true;
              return func;
            }
            return;
          }).filter(c => c);

          noActiveAgregaciones.forEach(c => c.activaUsuario = false);

          self.agregaciones = [...activeAgregaciones, ...noActiveAgregaciones];
        }
      } else {
        self.clearFilter();
        self.columns = JSON.parse(JSON.stringify(self.initialColumns));
      }
      self.forceUpdate(); 
    },
    loadFilters(filtro) {
      const self = this;
      if (self.$refs.rformfilter) {
        self.$refs.rformfilter.loadFilters(filtro);
      } else if (self.$refs.rformfilterHeader) {
        self.$refs.rformfilterHeader.loadFilters(filtro);
      }
    },
    getFilterData() {
      const self = this;
      if (self.$refs.rformfilter) {
        return self.$refs.rformfilter.formData;
      }
      else if (self.$refs.rformfilterHeader) {
        return self.$refs.rformfilterHeader.formData;
      }
    },
    clearFilter() {
      const self = this;
      if (self.$refs.rformfilter) {
        self.$refs.rformfilter.clear();
      }else if (self.$refs.rformfilterHeader) {
        self.$refs.rformfilterHeader.clear();
      }
    },
    forceUpdate() {
      const self = this;
      self.$forceUpdate(); 
      if (self.$refs.rformfilter) {
        self.$refs.rformfilter.$forceUpdate();
      }else if (self.$refs.rformfilterHeader) {
        self.$refs.rformfilterHeader.$forceUpdate();
      }
    },
    submitFilterForm() {
      const self = this;
      if (self.$refs.rformfilter) {
        self.$refs.rformfilter.submit();
      }else if (self.$refs.rformfilterHeader) {
        self.$refs.rformfilterHeader.submit();
      }
    },
    onPressEnter() {
      const self = this;
      self.$refs.btnSearchFilterForm.$el.click();
    },
    getConsultaDefecto: function() {
      const self = this;
      return self.tableUse.consultasFavoritas.filter(c => c.nombre == 'Defecto')[0];
    },
    getTextFiltrosActivos: function() {
      const self = this;
      const list = Object.entries(self.conditions)
        .filter(([key, value]) => (!key.endsWith('BOOLEANNO') || (key.endsWith('BOOLEANNO') && value)) && (!key.endsWith('BOOLEANSI') || (key.endsWith('BOOLEANSI') && value)) && (!key.endsWith('FechaRellena') || (key.endsWith('FechaRellena') && value)) && (!key.endsWith('FechaVacia') || (key.endsWith('FechaVacia') && value)) && !key.endsWith('MesActual') && !self.filtersNoCounters.includes(key) && value != null && typeof value != 'undefined' && value != '');
      var str = " | ";
      const columnas = self.tableUse.columnas;
      list.forEach(([nombre, valor]) => {
        var suffix = "";
        if (nombre.endsWith("Desde")) {
          nombre = nombre.substring(0, nombre.indexOf("Desde"));
          suffix = "Desde";
        } else if (nombre.endsWith("Hasta")) {
          nombre = nombre.substring(0, nombre.indexOf("Hasta"));
          suffix = "Hasta";
        } else if (nombre.endsWith("FechaVacia")) {
          nombre = nombre.substring(0, nombre.indexOf("FechaVacia"));
          suffix = "Vacía";
        } else if (nombre.endsWith("FechaRellena")) {
          nombre = nombre.substring(0, nombre.indexOf("FechaRellena"));
          suffix = "Rellena";
        } else if (nombre.endsWith("BOOLEANSI")) {
          nombre = nombre.substring(0, nombre.indexOf("BOOLEANSI"));
        } else if (nombre.endsWith("BOOLEANNO")) {
          nombre = nombre.substring(0, nombre.indexOf("BOOLEANNO"));
          valor = !valor;
        }
        const columna = columnas.filter(c => self.getFiltroPathName(c) == nombre)[0];
        if (columna && columna.filtro) {
          nombre = self.$t(columna.nombre);
          switch (columna.filtro.tipo) {
            case "FECHA":
              valor = DateUtils.dateFormat(valor, columna.formato);
              break;
            case "MULTISELECT":
              if (columna.mapValueFromFilterItems) {
                const itemList = columna.filtro.items;
                valor = valor.map(v => {
                  const item = itemList.filter(i => i.valor == v)[0];
                  if (item) {
                    return item.nombre;
                  }
                  return v;
                });
              }
              break;
            default:
              break;
          }
        }

        if (Array.isArray(valor)) {
          valor = valor.join(", ");
        }

        str = str + nombre + (suffix ? " " + suffix : "") + ": " + (typeof valor == 'boolean' ? (valor ? self.$t('common.yes') : self.$t('common.no')): valor);
        str = str + " | "
      });
      return str;
    },
    getColorItem: function(item, column) {
      const self = this;
      var context = self.getValorOriginalItem(item, column);
      const colores = column.colores;
      if (colores && colores.length) {
        const color = colores.filter(i => i.nombre == context)[0];
        if (color) {
          return color.valor;
        }
      }
    },
    getValorItem: function(item, column) {
      const self = this;
      if(column.opciones && column.opciones.evalPathName){
        try {
          return eval(column.opciones.evalPathName);
        } catch (error) {
          console.log("Error evaluando el evalPathName " + error);
          return "";
        }
      }
      var context = self.getValorOriginalItem(item, column);
      if (column.mapValueFromFilterItems) {
        const itemList = column.filtro.items;
        const item = itemList.filter(i => i.valor == context)[0];
        if (item) {
          context = item.nombre;
        }
      }
      if (Array.isArray(context) && !!!context.length) {
        return false;
      }
      return context;
    },
    getCleanPathName(pathName) {
      if (pathName.indexOf("|") > -1) {
        pathName = pathName.split("|")[0];
      }
      if (pathName.indexOf("##") > -1) {
        pathName = pathName.replaceAll("##", ".");
      }
      return pathName;
    },
    getValorOriginalItem: function(item, column) {
      const self = this;
      var pathName = self.getCleanPathName(column.pathName);
      var prefixVal = null;
      if (column.opciones && column.opciones.prefixPathName) {
        var prefixPathName = self.getCleanPathName(column.opciones.prefixPathName);
        var prefixNamespaces = prefixPathName.split(".");
        prefixVal = self.getValorObjectByNamespaces(item, prefixNamespaces, false)
      }
      var namespaces = pathName.split(".");
      var context = self.getValorObjectByNamespaces(item, namespaces, column.tipo == "LISTA")
      return prefixVal ? prefixVal + ' - ' + context : context;
    },
    getValorObjectByNamespaces(item, namespaces, returnArrayIfExists = false) {
      var context = item;
      for (var i = 0; i < namespaces.length; i++) {
        if (typeof context == "undefined") {
          let n = namespaces[i];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            context = [];
          } else {
            context = {};
          }
        }
        if (typeof context == "undefined" || context == null) context = {};
        if (!Array.isArray(context) || !returnArrayIfExists) {
          context = context[namespaces[i]];
        }
      }
      return context;
    },
    getFiltroPathName: function(column) {
      var pathName = column.filtro.pathName || column.pathName;
      return pathName.replaceAll(".", "##");
    },
    getFilterFromLocalStorage: function() {
      var self = this;
      var objReturn = {};
      if (self.filtersName) {
        objReturn = localStorage.getItem("filter-" + self.filtersName);
      }
      if (objReturn && typeof objReturn == "string") {
        try {
          objReturn = JSON.parse(objReturn);
        } catch (e) {
          objReturn = {};
        }
      }
      return objReturn || {};
    },
    reload() {
      var self = this;
      if (self.dbAdapter && typeof self.dbAdapter.resetCache == "function") {
        self.dbAdapter.resetCache();
      }
      self.refresh();
    },
    renderExternal(vl, vlData) {
      this.vlData = vlData;
    },
    getPrimary(data) {
      var self = this;
      var prim = "";
      self.primary.forEach(p => {
        var namespaces = p.split(".");
        if (prim) {
          prim = prim + "-";
        }
        // prim = prim + p;
        var val = this.getValorObjectByNamespaces(data, namespaces);
        prim = prim + val;
      })
      return prim;
    },
    toggleSelect: function(data, value) {
      var self = this;
      var dataSelected = self.getPrimary(data);
      if (value) {
        self.$set(self.selected, dataSelected, data);
      } else {
        self.$delete(self.selected, dataSelected);
      }
      self.$emit("select", Object.assign({}, self.selected));
    },
    toggleSelectAll: function(value) {
      var self = this;
      if (value) {
        self.vlData.items.forEach(function(data) {
          self.$set(self.selected, self.getPrimary(data), data);
        });
      } else {
        self.selected = {};
      }
    },
    paramsGetRemoteData: function() {
      const self = this;
      var [conds, filterAndList, funcionesAgregadas] = self.getFilterAndList();
      if (!!filterAndList && !!filterAndList.length) {
        conds.andList = filterAndList;
      }
      if (!!funcionesAgregadas && !!funcionesAgregadas.length) {
        conds.funcionesAgregadas = funcionesAgregadas;
      }
      if (!!!self.order) {
        self.order = "creationDate";
      }
      return {
        filter: conds,
        limit: self.rowsPerPageUsed == -1 ? null : self.rowsPerPageUsed,
        offset:
          self.rowsPerPageUsed == -1 ? null : self.rowsPerPageUsed * (self.page - 1),
        page: self.page,
        order: self.getCleanPathName(self.order),
        orderDir: self.orderDir,
        complete: self.complete,
        projection: self.getProjectionFields(conds)
      };
    },
    getFilterAndList: function() {
      const self = this;
      var conds = self.getConditionsWithValue();
      var filterAndList = self.getActiveColumnsFiltro().filter(c => {
        const pathName = self.getFiltroPathName(c);
        if (c.filtro.tipo == "FECHA" || c.filtro.tipo == "IMPORTE") {
          return !!conds[pathName + "Desde"] || !!conds[pathName + "Hasta"] || !!conds[pathName + "FechaVacia"] || !!conds[pathName + "FechaRellena"];
        } else if (c.filtro.tipo == "NUMERO") {
          return conds[pathName] != undefined;
        } else if (c.filtro.tipo == "BOOLEAN") {
          return !!conds[pathName + "BOOLEANSI"] || !!conds[pathName + "BOOLEANNO"];
        } else {
          return !!conds[pathName];
        }
      }).filter(c => {
        if (!!c.filtro.operation) {
          const pathName = self.getFiltroPathName(c);
          if (c.filtro.tipo == "FECHA" || c.filtro.tipo == "IMPORTE") {
            c.filtro.desde = conds[pathName + "Desde"];
            c.filtro.hasta = conds[pathName + "Hasta"];
            c.filtro.vacia = conds[pathName + "FechaVacia"];
            c.filtro.rellena = conds[pathName + "FechaRellena"];
            delete conds[pathName + "Desde"];
            delete conds[pathName + "Hasta"];
            delete conds[pathName + "FechaVacia"];
            delete conds[pathName + "FechaRellena"];
          } else if (c.filtro.tipo == "BOOLEAN") {
            c.filtro.booleanSI = conds[pathName + "BOOLEANSI"];
            c.filtro.booleanNO = conds[pathName + "BOOLEANNO"];
            delete conds[pathName + "BOOLEANSI"];
            delete conds[pathName + "BOOLEANNO"];
          } else {
            c.filtro.valor = conds[pathName];
            delete conds[pathName];
          } 
          return true;
        }
        return false;
      }).flatMap(c => {
        const pathName = self.getCleanPathName(self.getFiltroPathName(c));
        var arr = [];
        if (c.filtro.tipo == "FECHA" || c.filtro.tipo == "IMPORTE") {
          if (!!c.filtro.desde) {
            arr.push(
              {
                nombre: pathName,
                valor: c.filtro.tipo == "FECHA" ? new Date(c.filtro.desde) : c.filtro.desde,
                operacion: "GTE",
                subOperation: c.filtro.subOperation,
                function: c.filtro.function
              }
            );
          }
          if (!!c.filtro.hasta) {
            arr.push(
              {
                nombre: pathName,
                valor: c.filtro.tipo == "FECHA" ? new Date(c.filtro.hasta) : c.filtro.hasta,
                operacion: "LTE",
                subOperation: c.filtro.subOperation,
                function: c.filtro.function
              }
            );
          }
          if (!!c.filtro.vacia) {
            arr.push(
              {
                nombre: pathName,
                valor: true,
                operacion: "NOT_EXISTS",
                subOperation: c.filtro.subOperation,
                function: c.filtro.function
              }
            );
          }
          if (!!c.filtro.rellena) {
            arr.push(
              {
                nombre: pathName,
                valor: true,
                operacion: "EXISTS",
                subOperation: c.filtro.subOperation,
                function: c.filtro.function
              }
            );
          }
          
        } else if (c.filtro.tipo == "BOOLEAN") {
          // Si tenemos los dos booleanos a true o a false no filtramos por nada
          if ((!!c.filtro.booleanSI && !!c.filtro.booleanNO) || (!!!c.filtro.booleanSI && !!!c.filtro.booleanNO)) {
          } else {
            if (!!c.filtro.booleanSI) {
              arr.push({
                nombre: pathName,
                valor: true,
                operacion: "EQUALS"
              });
            } else {
              arr.push({
                nombre: pathName,
                valor: false,
                operacion: "EQUALS"
              });
              // arr.push({
              //   nombre: pathName,
              //   valor: [
              //     {
              //       nombre: pathName,
              //       operacion: "EQUALS",
              //       valor: false
              //     },
              //     {
              //       nombre: pathName,
              //       operacion: "EXISTS",
              //       valor: false
              //     }
              //   ],
              //   operacion: "OR"
              // });
            }
          }
        } else {
          arr.push({
            nombre: pathName,
            valor: c.filtro.valor,
            operacion: c.filtro.operation,
            subOperation: c.filtro.subOperation,
            function: c.filtro.function
          });
        }
        return arr;
      });
      var funcionesAgregadas = self.getActiveAgregaciones();
      return [conds, filterAndList, funcionesAgregadas];
    },
    getProjectionFields: function(conds) {
      const self = this;
      var projections = [...self.shownFields, ...self.getCondsProjection(conds), ...self.getProjectionsColumns().map(c => self.getCleanPathName(c.pathName)), ...self.getProjectionsColumns().filter(c => !!c.opciones && !!c.opciones.prefixPathName).map(c => self.getCleanPathName(c.opciones.prefixPathName)), ...self.getProjectionFiltersColumns(), ...self.getProjectionAgregacionesColumns(), ...self.getOrderProjection().map(c => self.getCleanPathName(c))];
      projections = projections.filter((item, index) => projections.indexOf(item) === index);
      return projections.join(",");
    },
    getProjectionFiltersColumns: function() {
      const self = this;
      return self.getProjectionsColumns().filter(c => c.filtro && c.filtro.operation && c.filtro.activo && !c.filtro.disableProjection && c.filtro.pathName).map(c => self.getCleanPathName(c.filtro.pathName));
    },
    getProjectionAgregacionesColumns: function() {
      const self = this;
      return self.getActiveAgregaciones().filter(c => !c.disableProjection).map(c => self.getCleanPathName(c.field));
    },
    getOrderProjection: function() {
      const self = this;
      const columnOrder = self.columns.filter(c => c.pathName == self.order);
      if (columnOrder && columnOrder.length) {
        return columnOrder.filter(c => !c.disableProjection).map(c => c.pathName);
      } else {
        return [self.order]
      }
    },
    getCondsProjection: function(conds) {
      const self = this;
      if(!conds || !conds.andList) return [];
      return conds.andList.filter(cond => {
        const columnOrder = self.columns.filter(c => !c.disableProjection && c.pathName == cond.nombre);
        return columnOrder && columnOrder.length;
      }).map(cond => cond.nombre);
    },
    getRemoteData: function(conAgregaciones) {
      var self = this;
      var app = self.$f7;
      var $content = self.$$(self.$el).find(".ptr-content");
      self.loadingInfinite = true;
      app.preloader.show();
      self.searched = true;
      var params = self.paramsGetRemoteData();
      params.filter.withoutAggregations = true;
      params.filter.onlyAggregations = false;
      params.filter = self.beforeSearch(params.filter);
      if (self.sendToSAP) {
        self.sendToSAP = false;
        self.dbAdapter
        .sendToSAP(params)
          .then(function(res) {
            app.dialog.alert("OK");
          })
          .catch(function(e) {
            if (typeof e == "string") e = { error: e };
            let error = e.message || e.error || e.error_description;
            app.dialog.alert(error);
            self.page = self.page - 1;
            self.nomore = true;
          })
          .then(function() {
            app.ptr.done($content);
            self.loadingInfinite = false;
            self.loadingPtr = false;
            app.preloader.hide();
          });
          return;
      }
      self.dbAdapter
        .getList(params)
        .then(function(res) {
          if (!res.list.length) {
            self.page = self.page - 1;
            self.nomore = true;
          } else {
            self.load(res.list);
          }
          self.$emit("load");
        })
        .catch(function(e) {
          if (typeof e == "string") e = { error: e };
          let error = e.message || e.error || e.error_description;
          app.dialog.alert(error);
          self.page = self.page - 1;
          self.nomore = true;
        })
        .then(function() {
          app.ptr.done($content);
          self.loadingInfinite = false;
          self.loadingPtr = false;
          app.preloader.hide();
        });
      if (conAgregaciones) {
        self.count = 0;
        self.agregacionesValor = [];
        self.loadingAggregations = true;
        params.filter.withoutAggregations = false;
        params.filter.onlyAggregations = true;
        self.dbAdapter
          .getList(params)
          .then(function(res) {
            self.count = res.totalCount;
            self.agregacionesValor = res.funcionesAgregadas || [];
          })
          .catch(function(e) {
            if (typeof e == "string") e = { error: e };
            let error = e.message || e.error || e.error_description;
            app.dialog.alert(error);
          })
          .then(function() {
            self.loadingAggregations = false;
          });
      }
    },
    refresh: function() {
      var self = this;
      var app = self.$f7;
      var $content = self.$$(self.$el).find(".ptr-content");
      app.ptr.refresh($content);
    },
    exportExcel: function(name) {
      var self = this;
      var app = self.$f7;
      var params = self.paramsGetRemoteData();
      params.name = name;
      params.columns = self.getActiveColumns();
      params.columns.forEach(c => {
        c.nombre = self.$t(c.nombre);
        c.pathName = self.getCleanPathName(c.pathName);
      })
      params.projection = params.projection.split(",");
      params.filter.withoutAggregations = true;
      params.filter.onlyAggregations = false;
      app.preloader.show();
      self.dbAdapter.exportDynamicExcel(params).finally(_ => app.preloader.hide());
    },
    initRemoteData: function() {
      var self = this;
      var app = self.$f7;
      self.page = 1;
      self.nomore = false;
      self.clearData();
      if (self._jqxhr) self._jqxhr.abort();
      self.loadingPtr = true;
      app.preloader.show();
      self.getRemoteData(!self.fromSort);
      self.fromSort = false;
    },
    load: function(data) {
      var self = this;
      self.addData(data);
    },
    addData: function(data) {
      var self = this;
      self.$set(self.vlData,"items",self.vlData.items.concat(data));
    },
    clearData: function() {
      var self = this;
      if (self.vlData.items) {
        self.$set(self.vlData, "items", []);
      }
      self.searched = false;
    },
    clearAll: function() {
      var self = this;
      self.clearData();
      self.order = self.sort;
      self.orderDir = self.sortDir;
      self.conditions = {};
      self.selected = [];
      self.agregacionesValor = [];
      self.count = 0;
    },
    setOrder: function(order) {
      var self = this;
      var app = self.$f7;
      self.order = order;
      if (self.orderDir == "DESC") {
        self.orderDir = "ASC";
      } else {
        self.orderDir = "DESC";
      }
      self.fromSort = true;
      if (self.searched) {
        self.refresh();
      }
    },
    setFilter: function(filter) {
      var self = this;
      var app = self.$f7;
      self.conditions = filter;
      //self.initRemoteData();
      self.refresh();
    },
    deleteItem: function(id) {
      var self = this;
      var app = self.$f7;
      self.$f7.dialog.confirm(
        self.$t("rlist.pregunta_eliminar_registro"),
        "Eliminar",
        function() {
          self.dbAdapter
            .delete({ itemId: id })
            .then(function(res) {
              if (res) {
                app.toast
                  .create({
                    icon: '<i class="f7-icons">checkmark</i>',
                    text: self.$t("rlist.registro_eliminado"),
                    position: "center",
                    destroyOnClose: true,
                    closeTimeout: 2000
                  })
                  .open();
                self.removeItem(id);
                self.$emit("deleteItem");
              }
            })
            .catch(function(error) {
              var message = typeof error == "string" ? error : error.message;
              app.dialog.alert(message);
            });
        }
      );
    },
    removeItem: function(id) {
      var self = this;
      let idx = self.vlData.items.findIndex(
        d => d[self.primary] == id
      );
      self.vlData.items.splice(idx, 1);
    },
    customHeaderClasses: function(column) {
      const self = this;
      const pathName = column.pathName; 
      const list = !self.getFilterData() ? [] : 
        Object.entries(self.getFilterData())
        .filter(([key, value]) => !self.filtersNoCounters.includes(key) && value != null && typeof value != 'undefined' && value != "" && (!Array.isArray(value) || (Array.isArray(value) && value.length)))
        .map(([key, value]) => {
          if (key.endsWith("Desde")) {
            key = key.substring(0, key.indexOf("Desde"));
          } else if (key.endsWith("Hasta")) {
            key = key.substring(0, key.indexOf("Hasta"));
          } else if (key.endsWith("FechaVacia") && value) {
            key = key.substring(0, key.indexOf("FechaVacia"));
          } else if (key.endsWith("FechaRellena") && value) {
            key = key.substring(0, key.indexOf("FechaRellena"));
          } else if (key.endsWith("BOOLEANSI") && value) {
            key = key.substring(0, key.indexOf("BOOLEANSI"));
          } else if (key.endsWith("BOOLEANNO") && value) {
            key = key.substring(0, key.indexOf("BOOLEANNO"));
          }
          return key;
        });
      var filterActive = list.indexOf(self.getFiltroPathName(column)) > -1;
      if (column.tipo == "LISTA") {
        filterActive = list.filter(l => self.getFiltroPathName(column).startsWith(l)).length > 0;
      }
      return {
        "label-cell": true,
        "sortable-cell": !column.disableOrder,
        "sortable-cell-active": self.order == pathName,
        "sortable-desc": self.orderDir == "DESC",
        "sortable-asc": self.orderDir == "ASC",
        "filter-cell": column.filtro && column.filtro.activo,
        "filter-cell-active": filterActive,
        "left-column-sticky": column.opciones && column.opciones.sticky
      };
    },
    customColumnClasses: function(column) {
      return {
        "left-column-sticky": column.opciones && column.opciones.sticky
      };
    },
    getClasses: function() {
      var self = this;
      var classes = Object.assign([], self.classList);
      if (!self.massiveActionsData) {
        // Si no hay acciones masivas sobre la tabla
        classes.push("hidden-checkboxes");
      }
      return classes;
    }
  }
};
</script>
<style scoped>
  .thead-sticky {
    position: sticky;
    top: -2px;
    z-index: 19;
    /* border: 1px solid lightgrey; */
    /* border-width: 1px 0; */
  }
  .thead-sticky .line {
    height: 30px;
  }
  .thead-sticky .line .cell {
    height: 30px;
    line-height: 26px;
    padding: 2px 12px;
  }
  .virtual-list {
    height: calc(100% - 30px) !important;
    min-height: 200px;
  }
  .contenido-lista {
    max-height: 100%;
  }
  .contenido-lista .line {
    height: 24px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.1);
    border-width: 1px 0 0;
  }
  .cell {
    display: inline-block;
    height: 26px;
    overflow: hidden;
    line-height: 23px;
    padding: 1px 12px;
    box-sizing: border-box;
    margin-top: -1px;
    position:relative;
  }
  .popupHeight {
    overflow: auto;
    height: calc(var(--f7-popup-tablet-height) - (var(--f7-navbar-height) + var(--f7-safe-area-top)));
  }
  .thead-sticky div.line:last-child > * {
    top: 0;
  }
  .tfoot-sticky div.line:first-child > * {
    bottom: 0;
  }
  .table-content {
    overflow-x: auto;
  }
  .tfoot-sticky div.line > *,
  .thead-sticky div.line > * {
    position: -webkit-sticky !important;
    position: sticky !important;
    opacity: 1;
    z-index: 16;
    background: #fff;
  }
  .left-column-sticky {
    left: 0;
  }
  .right-column-sticky {
    right: 0;
  }
  .left-column-sticky,
  .right-column-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
    opacity: 1;
    z-index: 17 !important;
    background: #f7f7f8;
  }

  .thead-sticky div.line > *.left-column-sticky,
  .thead-sticky div.line > *.right-column-sticky {
    z-index: 18 !important;
  }
</style>
<style>

.data-table .filter-cell .filter-icon i {
  top: 3px;
}
.data-table .filter-cell:not(.filter-cell-active) .filter-icon i {
  /* color: lightsteelblue; */
  color: lightgray;
}

.data-table .filter-cell:not(.filter-cell-active) .filter-icon-delete i {
  /* color: lightsteelblue; */
  visibility: hidden;
}
.data-table .filter-cell .filter-icon-delete i {
  /* color: lightsteelblue; */
  color: red;
  font-size: small;
  margin-bottom: 10px;
}

</style>
<style scoped>
.data-table div.line.trclicked, .data-table tbody div.line.trclicked:hover {
  background-color: #746f6f5e;
}
.aggregation-div {
  width: 80%;
  max-height: 100px;
  overflow-y: auto;
}
.row-aggregation {
  justify-content: flex-start;
}
.subindice {
  font-size: 10px;
}
.list-columnas {
  max-height: 250px;
  overflow-y: auto;
}
.data-table-header {
  z-index: 3000;
}
.badge-filtros {
  height: auto;
  max-height: 100px;
  overflow-y: auto;
}
.ptr-content {
  overflow: visible !important;
}
.full-height {
  display: flex;
  flex-flow: column;
  height: 100% !important;
}

.virtual-list-parent {
  overflow: auto;
}

div.cell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.infinite-scroll-content {
  overflow-x: auto;
}

.hidden-checkboxes .checkbox { 
  visibility: hidden;
}

.columnaComentario {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

