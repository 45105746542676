<template>
  
  <div class="list list-form no-hairlines">
    <div v-if="caracteristica.tipo == 'GROUP'">
      <f7-card-header>
        {{caracteristica.nombre}}
        <f7-icon 
          v-roles="{route: $f7route.url, roles: ['INSPECTOR', 'ADMINISTRATIVO']}"
          v-if="caracteristica.help && caracteristica.help != ''" f7="info_circle_fill" color="blue" 
          v-tooltip="caracteristica.help"
        ></f7-icon>
      </f7-card-header>
    </div>
    <div v-else  class="row">
      <f7-col width="75">
        <r-input
          v-if="caracteristica.tipo == 'TEXT'"
          floating-label
          :label="caracteristica.nombre"
          :form="form"
          type="text"
          class="caracteristica-descripcion"
          :name="name + '.valor'"
        ></r-input>
        <r-input
          v-if="caracteristica.tipo == 'TEXTAREA'"
          floating-label
          :label="caracteristica.nombre"
          :form="form"
          type="textarea"
          class="caracteristica-descripcion"
          :name="name + '.valor'"
        ></r-input>
        <r-input
          v-if="caracteristica.tipo == 'DATE'"
          floating-label
          :label="caracteristica.nombre"
          :form="form"
          type="datepicker"
          class="caracteristica-descripcion"
          :name="name + '.valor'"
          :calendar-params="{timePickerPlaceholder:'Selecciona hora', toolbarCloseText:'Hecho',closeOnSelect:true,timePicker: true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit', hour:'2-digit',minute:'2-digit' } }"
        ></r-input>
        <r-input
          v-if="caracteristica.tipo == 'SIMPLELIST' || caracteristica.tipo == 'RADIO' || caracteristica.tipo == 'CHECK'"
          floating-label
          :label="caracteristica.nombre"
          :form="form"
          type="select"
          class="caracteristica-descripcion"
          :name="name + '.valor'"
        >

          <option selected disabled></option>
          <option v-for="(item,index) in caracteristica.opciones"
              :key="name + '-' +caracteristica.nombre+'-'+index" :value="item">{{item}}</option>
        </r-input>
        <r-input
          v-if="caracteristica.tipo == 'MULTILIST' || caracteristica.tipo == 'MULTICHECK'"
          :label="caracteristica.nombre"
          :form="form"
          type="select"
          class="caracteristica-descripcion"
          :multiple="true"
          :name="name + '.valor'"
        >
          <option selected disabled hidden></option>
          <option v-for="(item,index) in caracteristica.opciones"
              :key="name + '-' +caracteristica.nombre+'-'+index" :value="item">{{item}}</option>
        </r-input>
        <r-input
          v-if="caracteristica.tipo == 'CHECK'"
          floating-label
          :label="caracteristica.nombre"
          :form="form"
          type="check"
          class="caracteristica-descripcion"
          :name="name + '.valor'"
        ></r-input>
      </f7-col>
      <f7-col width="25">
        <f7-icon 
          v-roles="{route: $f7route.url, roles: ['INSPECTOR', 'ADMINISTRATIVO']}"
          v-if="caracteristica.help && caracteristica.help != ''" f7="info_circle_fill" color="blue" 
          v-tooltip="caracteristica.help"
          class="caracteristica-icono"
        ></f7-icon>
      </f7-col>
    </div>
    <div
          v-for="(item,index) in caracteristica.hijas"
          :key="name + '-hijas-'+index"
        >
        <r-caracteristica :caracteristica="item" :name="name+'.hijas.'+index" :form="form"></r-caracteristica>
    </div>

  </div>

</template>
<style>
  div.list{
    list-style: none;
  }
  select[multiple] {
    height: 100px;
    margin-top: 10px; 
  }
  .caracteristica-descripcion{
    margin-top: 20px;
    margin-left: 20px;
  }
  i.caracteristica-icono{
    margin-top: 40px;
    margin-left: 20px;
  }
</style>
<script>
import rInput from "./rInput.vue";
export default {
  name: "r-caracteristica",
  components: {
    rInput
  },
  data() {
    return {
      self: this
    };
  },
  methods: {},
  props: {
    caracteristica: {
      required: true
    },
    name: {
      required: true,
      type: String
    },
    form: {
      required: true
    }
  },
  mounted() {
    var $ = this.$$;
    var $el = $(this.$el);
    var self = this;
  }
};
</script>