import Inspecciones from './inspecciones.vue';
import InspeccionForm from './inspeccion-form.vue';
import RoutesUtils from './../../js/RoutesUtils.js';

var routes = [{
        path: '/inspecciones/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('inspecciones', ['ADMINISTRATIVO', 'CLIENTE', 'INSPECTOR'], Inspecciones),
        keepAlive: true
    },
    // {
    //     path: '/inspecciones/new/',
    //     beforeEnter: RoutesUtils.checkAuthPage,
    //     async: RoutesUtils.checkPermission('inspecciones/new', InspeccionForm)
    // },
    // {
    //     path: '/inspecciones/edit/:id/',
    //     beforeEnter: RoutesUtils.checkAuthPage,
    //     async: RoutesUtils.checkPermission('inspecciones/edit', InspeccionForm)
    // },
    {
        path: '/inspecciones/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('inspecciones/view', ['ADMINISTRATIVO', 'CLIENTE', 'INSPECTOR'], InspeccionForm, { editable: false })
    },
];
export default routes;