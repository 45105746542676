<template>
  <f7-page name="home" :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar :sliding="false">
      <f7-nav-left></f7-nav-left>
      <f7-nav-title sliding>Panel de control</f7-nav-title>
      <f7-nav-right></f7-nav-right>
    </f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <f7-list class="list-form">
        <f7-row>
          <f7-button
            fill
            v-roles="{route: $f7route.url, roles: ['CLIENTE']}"
            icon-f7="doc_text_fill"
            text="Crear Nueva Cita"
            class="display-flex margin col-25"
            href="/pedidos/new/"
          ></f7-button>
        </f7-row>
        <div class="dashboards">
          <div class="lista" v-if="list.filter(t => !t.tipo).length">
            <f7-card v-for="(item,index) in list.filter(t => !t.tipo)" :key="'lista1-'+index"  >
              <f7-card-content>
                <!-- <f7-icon f7="gear_alt_fill">{{item.imagen}}</f7-icon> -->
                <i class="f7-icons"
                  :class="'text-color-' + item.color"
                >{{item.imagen}}</i>
                {{item.nombre}}: <span :class="'badge color-' + item.color ">{{item.valor}}</span>
              </f7-card-content>
            </f7-card>
          </div>
          <div class="lista" v-if="list.filter(t => t.tipo == 'VEHICULO').length">
            <f7-card v-for="(item,index) in list.filter(t => t.tipo == 'VEHICULO')" :key="'lista2-'+index"  >
              <f7-card-content>
                <!-- <f7-icon f7="gear_alt_fill">{{item.imagen}}</f7-icon> -->
                <i class="f7-icons"
                  :class="'text-color-' + item.color"
                >{{item.imagen}}</i>
                {{item.nombre}}: <span :class="'badge color-' + item.color ">{{item.valor}}</span>
              </f7-card-content>
            </f7-card>
          </div>
          <div class="lista" v-if="list.filter(t => t.tipo == 'INSPECCION').length">
            <f7-card v-for="(item,index) in list.filter(t => t.tipo == 'INSPECCION')" :key="'lista3-'+index"  >
              <f7-card-content>
                <!-- <f7-icon f7="gear_alt_fill">{{item.imagen}}</f7-icon> -->
                <i class="f7-icons"
                  :class="'text-color-' + item.color"
                >{{item.imagen}}</i>
                {{item.nombre}}: <span :class="'badge color-' + item.color ">{{item.valor}}</span>
              </f7-card-content>
            </f7-card>
          </div>
        </div>
        <f7-row no-gap>
          <f7-col width="100" large="50" 
            v-roles="{route: $f7route.url, roles: ['ADMINISTRATIVO', 'INSPECTOR', 'CLIENTE'], tipoCliente: ['MARCA','GRUPO_AUTOMOCION']}"
          >
            <f7-card>
              <f7-card-content>
                <apexchart height="350" type="bar" :options="chartOptions('Inspecciones realizadas por Concesionario','bar')" :series="prepararDatos(inspeccionesRealizadasMod,'treemap')">
                </apexchart>
                <bar-chart class="chart-horizontal-label" :data="inspeccionesRealizadasConc" title="Inspecciones realizadas por Concesionario" orientation="H" :height="inspeccionesRealizadasConc.length * 30">
                   <r-form slot="filter">
                    <template v-slot="{rform}">
                      <f7-list no-hairlines-md>
                        <template v-slot:list>
                          <f7-row class="margin-horizontal">
                            <r-input
                              floating-label
                              :form="rform"
                              type="select"
                              name="groupBy"
                              hidden
                            >
                              <option selected value="CONCESIONARIO">CONCESIONARIO</option>
                            </r-input>
                            <div class="col-20"></div>
                            <r-autocomplete
                              ref="anyo1"
                              class="col-30"
                              :dbAdapter="Anyo"
                              floating-label
                              :label="$t('dashboard.anyo')"
                              :form="rform"
                              name="annio"
                              idProp="valor"
                              labelProp="nombre"
                              :filter="{num: 5}"
                              @change="searchInpeccionesRealizadas(rform.formData);"
                              :autoSelectValue="{valor:new Date().getFullYear()}"
                            ></r-autocomplete>
                            <r-autocomplete
                              class="col-30"
                              :dbAdapter="Mes"
                              floating-label
                              :label="$t('dashboard.mes')"
                              :form="rform"
                              name="mes"
                              idProp="valor"
                              labelProp="nombre"
                              @change="searchInpeccionesRealizadas(rform.formData);"
                            ></r-autocomplete>
                            <div class="col-20"></div>
                          </f7-row>
                        </template>
                      </f7-list>
                    </template>
                  </r-form>
                </bar-chart>
              </f7-card-content>
            </f7-card>
          </f7-col>
          <f7-col width="100" large="50">
            <f7-card>
              <f7-card-content>
                <!-- <apexchart height="350" type="treemap" :options="chartOptions('Inspecciones realizadas por Modelo','treemap')" :series="prepararDatos(inspeccionesRealizadasMod,'treemap')">
                </apexchart> -->
                <pie-chart :data="inspeccionesRealizadasMod" title="Inspecciones realizadas por Modelo">
                  <r-form slot="filter">
                    <template v-slot="{rform}">
                      <f7-list no-hairlines-md>
                        <template v-slot:list>
                          <f7-row class="margin-horizontal">
                            <r-input
                              floating-label
                              :form="rform"
                              type="select"
                              name="groupBy"
                              hidden
                            >
                              <option selected value="MODELO">MODELO</option>
                            </r-input>
                            <div class="col-10"></div>
                            <r-autocomplete
                              class="col-25"
                              :dbAdapter="Anyo"
                              floating-label
                              :label="$t('dashboard.anyo')"
                              :form="rform"
                              name="annio"
                              idProp="valor"
                              labelProp="nombre"
                              :filter="{num: 5}"
                              @change="searchInpeccionesRealizadas(rform.formData);"
                              :autoSelectValue="{valor:new Date().getFullYear()}"
                            ></r-autocomplete>
                            <r-autocomplete
                              class="col-25"
                              :dbAdapter="Mes"
                              floating-label
                              :label="$t('dashboard.mes')"
                              :form="rform"
                              name="mes"
                              idProp="valor"
                              labelProp="nombre"
                              @change="searchInpeccionesRealizadas(rform.formData);"
                            ></r-autocomplete>
                            <r-autocomplete
                              v-roles="{route: $f7route.url, roles: ['ADMINISTRATIVO', 'INSPECTOR', 'CLIENTE'], tipoCliente: ['MARCA','GRUPO_AUTOMOCION']}"
                              class="col-30"
                              :dbAdapter="Cliente"
                              floating-label
                              :label="$t('dashboard.cliente')"
                              :form="rform"
                              name="clienteId"
                              labelProp="nombre"
                              idProp="id"
                              @change="searchInpeccionesRealizadas(rform.formData);"
                            ></r-autocomplete>
                            <div class="col-10"></div>
                          </f7-row>
                        </template>
                      </f7-list>
                    </template>
                  </r-form>
                </pie-chart>
              </f7-card-content>
            </f7-card>
          </f7-col>
          <f7-col width="100" large="50"
            v-roles="{route: $f7route.url, roles: ['ADMINISTRATIVO', 'INSPECTOR', 'CLIENTE'], tipoCliente: ['MARCA','GRUPO_AUTOMOCION']}"
          >
            <f7-card> 
              <f7-card-content>
                <bar-chart class="chart-horizontal-label" :data="inspeccionesKOConc" title="Inspecciones DESFAVORABLES por Concesionario" orientation="H" :height="inspeccionesKOConc.length * 30">
                   <r-form slot="filter">
                    <template v-slot="{rform}">
                      <f7-list no-hairlines-md>
                        <template v-slot:list>
                          <f7-row class="margin-horizontal">
                            <r-input
                              floating-label
                              :form="rform"
                              type="select"
                              name="groupBy"
                              hidden
                            >
                              <option selected value="CONCESIONARIO">CONCESIONARIO</option>
                            </r-input>
                            <div class="col-20"></div>
                            <r-autocomplete
                              ref="anyo1"
                              class="col-30"
                              :dbAdapter="Anyo"
                              floating-label
                              :label="$t('dashboard.anyo')"
                              :form="rform"
                              name="annio"
                              idProp="valor"
                              labelProp="nombre"
                              :filter="{num: 5}"
                              @change="searchInpeccionesKO(rform.formData);"
                              :autoSelectValue="{valor:new Date().getFullYear()}"
                            ></r-autocomplete>
                            <r-autocomplete
                              class="col-30"
                              :dbAdapter="Mes"
                              floating-label
                              :label="$t('dashboard.mes')"
                              :form="rform"
                              name="mes"
                              idProp="valor"
                              labelProp="nombre"
                              @change="searchInpeccionesKO(rform.formData);"
                            ></r-autocomplete>
                            <div class="col-20"></div>
                          </f7-row>
                        </template>
                      </f7-list>
                    </template>
                  </r-form>
                </bar-chart>
              </f7-card-content>
            </f7-card>
          </f7-col>
          <f7-col width="100" large="50">
            <f7-card>
              <f7-card-content>
                <pie-chart :data="inspeccionesKOMod" title="Inspecciones DESFAVORABLES por Modelo">
                  <r-form slot="filter">
                    <template v-slot="{rform}">
                      <f7-list no-hairlines-md>
                        <template v-slot:list>
                          <f7-row class="margin-horizontal">
                            <r-input
                              floating-label
                              :form="rform"
                              type="select"
                              name="groupBy"
                              hidden
                            >
                              <option selected value="MODELO">MODELO</option>
                            </r-input>
                            <div class="col-10"></div>
                            <r-autocomplete
                              class="col-25"
                              :dbAdapter="Anyo"
                              floating-label
                              :label="$t('dashboard.anyo')"
                              :form="rform"
                              name="annio"
                              idProp="valor"
                              labelProp="nombre"
                              :filter="{num: 5}"
                              @change="searchInpeccionesKO(rform.formData);"
                              :autoSelectValue="{valor:new Date().getFullYear()}"
                            ></r-autocomplete>
                            <r-autocomplete
                              class="col-25"
                              :dbAdapter="Mes"
                              floating-label
                              :label="$t('dashboard.mes')"
                              :form="rform"
                              name="mes"
                              idProp="valor"
                              labelProp="nombre"
                              @change="searchInpeccionesKO(rform.formData);"
                            ></r-autocomplete>
                            <r-autocomplete
                              v-roles="{route: $f7route.url, roles: ['ADMINISTRATIVO', 'INSPECTOR', 'CLIENTE'], tipoCliente: ['MARCA','GRUPO_AUTOMOCION']}"
                              class="col-30"
                              :dbAdapter="Cliente"
                              floating-label
                              :label="$t('dashboard.cliente')"
                              :form="rform"
                              name="clienteId"
                              labelProp="nombre"
                              idProp="id"
                              @change="searchInpeccionesKO(rform.formData);"
                            ></r-autocomplete>
                            <div class="col-10"></div>
                          </f7-row>
                        </template>
                      </f7-list>
                    </template>
                  </r-form>
                </pie-chart>
              </f7-card-content>
            </f7-card>
          </f7-col>
          <f7-col width="100" large="100">
            <f7-card>
              <f7-card-content>
                <pie-chart :data="comprobacionesKO" :title="'Comprobaciones DESFAVORABLES ' + nombreCliente">
                  <r-form slot="filter">
                    <template v-slot="{rform}">
                      <f7-list no-hairlines-md>
                        <template v-slot:list>
                          <f7-row class="margin-horizontal">
                            <div class="col-10"></div>
                            <r-autocomplete
                              class="col-25"
                              :dbAdapter="Anyo"
                              floating-label
                              :label="$t('dashboard.anyo')"
                              :form="rform"
                              name="annio"
                              idProp="valor"
                              labelProp="nombre"
                              :filter="{num: 5}"
                              @change="searchComprobacionesKO(rform.formData);"
                              :autoSelectValue="{valor:new Date().getFullYear()}"
                            ></r-autocomplete>
                            <r-autocomplete
                              class="col-25"
                              :dbAdapter="Mes"
                              floating-label
                              :label="$t('dashboard.mes')"
                              :form="rform"
                              name="mes"
                              idProp="valor"
                              labelProp="nombre"
                              @change="searchComprobacionesKO(rform.formData);"
                            ></r-autocomplete>
                            <r-autocomplete
                              v-roles="{route: $f7route.url, roles: ['ADMINISTRATIVO', 'INSPECTOR', 'CLIENTE'], tipoCliente: ['MARCA','GRUPO_AUTOMOCION']}"
                              class="col-30"
                              :dbAdapter="Cliente"
                              floating-label
                              :label="$t('dashboard.cliente')"
                              :form="rform"
                              name="clienteId"
                              labelProp="nombre"
                              idProp="id"
                              @change="searchComprobacionesKO(rform.formData);"
                            ></r-autocomplete>
                            <div class="col-10"></div>
                          </f7-row>
                        </template>
                      </f7-list>
                    </template>
                  </r-form>
                </pie-chart>
              </f7-card-content>
            </f7-card>
          </f7-col>
        </f7-row>
      </f7-list>
    </f7-page-content>
  </f7-page>
</template>
<style scoped>
  .badge {
    top: -2px;
  }
  .dashboards {
    display: flex;
  }
  .dashboards .lista {
    width: 100%;
  }
</style>
<style>
  .chart-horizontal-label .g-label {
    left: 0 !important;
    font-size: 11px;
    line-height: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
</style>
<script>
import Dashboard from "./../js/db/Dashboard.js";
import Anyo from "./../js/db/Anyo.js";
import Mes from "./../js/db/Mes.js";
import Cliente from "./../js/db/Cliente.js";
import barChart from "./../components/charts/barChart.vue";
import pieChart from "./../components/charts/pieChart.vue";
import rForm from "./../components/rForm.vue";
import rInput from "./../components/rInput.vue";
import rAutocomplete from "./../components/rAutocomplete.vue";

export default {
  components: {
    barChart,
    pieChart,
    rForm,
    rInput,
    rAutocomplete
  },
  data() {
    return {
      Dashboard, 
      Anyo,
      Mes,
      Cliente,
      list: [],
      inspeccionesRealizadasConc : [],
      inspeccionesRealizadasMod : [],
      inspeccionesKOConc : [],
      inspeccionesKOMod : [],
      comprobacionesKO : [],
      promesadefiltro : null,
      nombreCliente: ''
    };
  },
  methods: {
    chartOptions: function(titulo, tipo) {
      let chartOptions;
      switch (tipo) {
        case "bar":
          chartOptions =  {
                    legend: { show: false },
                    chart: { height: 350, type: 'bar', stacked: true },
                    title: { text: titulo },
                    dataLabels: {
                      enabled: true,
                      style: { fontSize: '12px', },
                      formatter: function(text, op) { return [text, op.value] },
                      offsetY: -4
                    },
                    responsive: [{
                      breakpoint: 480,
                      options: {
                        legend: {
                          position: 'bottom',
                          offsetX: -10,
                          offsetY: 0
                        }
                      }
                    }],
                    plotOptions: {
                      bar: {
                        horizontal: true,
                        dataLabels: {
                          total: {
                            enabled: true,
                            offsetX: 0,
                            style: {
                              fontSize: '13px',
                              fontWeight: 900
                            }
                          }
                        }
                      }
                    },
                    stroke: {
                      width: 1,
                      colors: ['#fff']
                    },
                  };
          break;
      
        case "treemap":
          chartOptions =  {
                    legend: { show: false },
                    chart: { height: 350, type: 'treemap' },
                    title: { text: titulo },
                    dataLabels: {
                      enabled: true,
                      style: { fontSize: '12px', },
                      formatter: function(text, op) { return [text, op.value] },
                      offsetY: -4
                    },
                    plotOptions: {
                      treemap: {
                        enableShades: true,
                        shadeIntensity: 0.5,
                        reverseNegativeShade: true,
                        colorScale: {
                          ranges: [
                            { from:    -6, to: 0, color: '#CD363A' },
                            { from: 0.001, to: 6, color: '#52B12C' }
                          ]
                        }
                      }
                    }
                  };
          break;
      
        default:
          break;
      }



    },
    prepararDatos(inspeccionesKOMod,tipo) 
    {
      const self = this;
      const app = self.$f7;
      var series = [];
      var data = [];
      series.push({data: data});
      for (var i = 0; i < inspeccionesKOMod.length; i++) {
        var inspeccion = inspeccionesKOMod[i];
        var dato = {
          x: inspeccion.nombre,
          y: inspeccion.valor
        };
        data.push(dato);
      }
      return series;
    },
    searchInpeccionesRealizadas: function(filter) {
      var self = this;
      Dashboard.inspeccionesRealizadas(filter).then(function(res) {
        if (filter.groupBy == "CONCESIONARIO") {
          self.inspeccionesRealizadasConc = res.dto.valores;
        } else if (filter.groupBy == "MODELO") {
          self.inspeccionesRealizadasMod = res.dto.valores;
        }
      });
    },
    searchInpeccionesKO: function(filter) {
      var self = this;
      Dashboard.inspeccionesKO(filter).then(function(res) {
        if (filter.groupBy == "CONCESIONARIO") {
          self.inspeccionesKOConc = res.dto.valores;
        } else if (filter.groupBy == "MODELO") {
          self.inspeccionesKOMod = res.dto.valores;
        }
      });
    },
    searchComprobacionesKO: function(filter) {
      var self = this;
      let promesaactual = self.promesadefiltro = Dashboard.comprobacionesKO(filter);
      self.comprobacionesKO = [];
      promesaactual.then(function(res) {
        if(self.promesadefiltro !== promesaactual) {
          return;
        }
        self.comprobacionesKO = res.dto.valores;
        self.promesadefiltro = null;
        let params = {
          itemId: filter.clienteId
        };
        if(!filter.clienteId) {
          self.$set(self, 'nombreCliente', '');
        } else {
          Cliente.get(params).then(function(res) {
            self.$set(self, 'nombreCliente', res?res.nombre:'');
          }).catch(function({ xhr, status, message }) {
            self.$set(self, 'nombreCliente', '');
          });
        }
      });
    }
  },
  created: function() {
    var self = this;
    var app = self.$f7;
    Dashboard.getList().then(function(res) {
      self.$set(self, 'list', res.list);
    }); 
  }
};
</script>