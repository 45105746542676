import Framework7 from 'framework7/framework7-lite.esm.bundle.js';
import DatabaseModel from './DatabaseModel.js';
import Inspector from './Inspector.js';
import UsuarioCliente from './UsuarioCliente.js';

var Usuario = Object.create(DatabaseModel);

Usuario.model = 'user';

Usuario.getList = function(params, forceReload) {
    var self = this;
    params = params || {}
    var paramsDefault = {
        currentPage: (params.page || 1) - 1,
        perPage: params.limit,
        sortBy: params.order,
        sortDesc: params.orderDir == 'DESC' ? true : false,
        complete: params.complete,
        projection: params.projection,
        operation: params.operation
    };
    var params_ser = Framework7.utils.serializeObject(paramsDefault);
    var paramsRequest = {
        url: self.server + 'security/' + self.model + '/SIMECAR_INSPECCIONES/search/?' + params_ser,
        method: 'POST',
        data: params.filter || {}
    };
    return self.sendRequest(paramsRequest, !forceReload);
};

Usuario.putAll = function(params) {
    var self = this;
    params = params || {};
    if (!params.itemIds) reject({ error: 'itemIds not defined' });
    var params = {
        url: self.server + 'security/' + self.model + '/updatemany',
        method: 'PUT',
        data: {
            ids: params.itemIds,
            dto: params.data
        }
    };
    return self.sendRequest(params, false).then(function(res) {
        //CacheService.clearCache(self.model);
        return res;
    });
};

// Usuario.getList = function(params) {
//     var self = this;
//     params = params || {};
//     return new Promise(function(resolve, reject) {
//         self.request.promise({
//             url: self.server + 'security/' + self.model + '/SIMECAR_INSPECCIONES/search/',
//             method: 'POST',
//             contentType: 'application/json',
//             dataType: 'json',
//             crossDomain: true
//         }).then(function({ data, xhr, status }) {
//             if (data.error) {
//                 reject(data);
//             } else {
//                 resolve(data);
//             }
//         }).catch(function({ xhr, status, message }) {
//             try {
//                 reject(JSON.parse(xhr.response).error);
//             } catch (e) {
//                 reject(xhr.response);
//             }
//         });
//     });
// };

Usuario.get = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {
        if (!params.itemId) reject({ error: 'itemId not defined' });
        self.request.promise({
            url: self.server + 'security/' + self.model + '/SIMECAR_INSPECCIONES/' + params.itemId + '/',
            method: 'GET',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: {}
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data.dto);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
};

Usuario.save = function(params) {
    var self = this;
    if (params.itemId) {
        return self.put(params);
    } else {
        return self.post(params);
    }
}

Usuario.put = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {
        if (!params.itemId) reject({ error: 'itemId not defined' });
        self.request.promise({
            url: self.server + 'security/' + self.model + '/SIMECAR_INSPECCIONES/' + params.itemId + '/',
            method: 'PUT',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: params.data
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data.dto);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
}

Usuario.post = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server + 'security/' + self.model + '/SIMECAR_INSPECCIONES/',
            method: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: params.data
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data.dto);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
}

Usuario.delete = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {
        if (!params.itemId) reject({ error: 'itemId not defined' });
        self.request.promise({
            url: self.server + 'security/' + self.model + '/SIMECAR_INSPECCIONES/' + params.itemId + '/',
            method: 'DELETE',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: params.data
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                if (data.dto && data.dto.application && data.dto.application.roles && (data.dto.application.roles.indexOf('CLIENTE') > -1 || data.dto.application.roles.indexOf('ADMINISTRATIVO') > -1)) {
                    UsuarioCliente.delete({itemId: data.dto.username})
                } else if (data.dto && data.dto.application && data.dto.application.roles && data.dto.application.roles.indexOf('INSPECTOR') > -1) {
                    Inspector.delete({itemId: data.dto.username})
                }
                resolve(data.dto);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
}

Usuario.auth = function(params) {
    var self = this;
    //params.username = 'USER_INSPECCIONES_ADMIN';
    //params.password = 'ADMIN';
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server + 'security/oauth/token',
            method: 'POST',
            contentType: 'application/x-www-form-urlencoded',
            dataType: 'json',
            crossDomain: true,
            headers: {
                'Authorization': 'Basic ' + btoa(self.client_id + ":" + self.client_secret)
            },
            data: {
                grant_type: 'password',
                username: params.username,
                password: params.password
            }
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data);
            }

        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response));
            } catch (e) {
                reject(xhr.response);
            }
        });

    });
};

Usuario.me = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server + 'security/me/SIMECAR_INSPECCIONES',
            method: 'GET',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data.dto);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response));
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
};

Usuario.cambiarPass = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server + 'security/' + self.model + '/change_password',
            method: 'PUT',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: params
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data);
            }

        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response));
            } catch (e) {
                reject(xhr.response);
            }
        });

    });
};

Usuario.resetPass = function(params) {
    var self = this;
    if (!params.itemId) reject({ error: 'itemId not defined' });
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server + 'security/' + self.model + '/reset_password/' + params.itemId,
            method: 'PUT',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data);
            }

        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response));
            } catch (e) {
                reject(xhr.response);
            }
        });

    });
};

export default Usuario;